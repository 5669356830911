import { isImage, isVideo } from './fileExtensions';

const attachmentPreviewable = (attachment) => {
  return (
    (isImage(attachment.type) &&
      !attachment.type.includes('heif') &&
      !attachment.type.includes('heic')) ||
    isVideo(attachment.type)
  );
};

export default attachmentPreviewable;
