import constants from '@/utilities/constants';

export default [
  {
    path: '/workspaces/new',
    name: constants.routeNames.newWorkspace,
    component: () =>
      import(/* webpackChunkName: "workspaces" */ '@/components/pages/workspaces/NewWorkspace.vue'),
  },
  {
    path: '/workspaces',
    name: constants.routeNames.workspaces,
    component: () =>
      import(
        /* webpackChunkName: "workspaces" */ '@/components/pages/workspaces/ListWorkspaces.vue'
      ),
  },
  {
    path: `/${constants.routeNames.onboardingInfo}`,
    name: constants.routeNames.onboardingInfo,
    component: () =>
      import(/* webpackChunkName: "workspaces" */ '@/components/pages/OnboardingGatheringInfo.vue'),
  },
];
