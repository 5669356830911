import { ActionTree } from 'vuex';
import { EditorState, EditorService } from './state';
import { EditorUpload } from './types';

export default {
  setEditor({ commit }, payload: EditorService): void {
    commit('setEditor', payload);
  },

  deleteEditor({ commit }, id: string): void {
    commit('deleteEditor', id);
  },

  setCurrentEditorId({ commit }, id) {
    commit('setCurrentEditorId', id);
  },

  setCurrentEditorResourceType({ commit }, resourceType) {
    commit('setCurrentEditorResourceType', resourceType);
  },

  updateEditorId(
    { commit },
    {
      oldId = 'editor-page-newpage',
      newId,
      resourceId,
    }: { oldId?: string; newId: string; resourceId: string }
  ) {
    commit('updateEditorId', { oldId, newId, resourceId });
  },

  setPersistedPagesKeys({ commit }, keys) {
    commit('setPersistedPagesKeys', keys);
  },

  addPageKeyToPersistedKeys({ commit, getters }, key) {
    if (!getters.checkIfDbExists(key)) {
      commit('addPageKeyToPersistedKeys', key);
    }
  },
  setSuggesterShown({ commit }, payload) {
    commit('setSuggesterShown', payload);
  },
  insertOrUpdateEditorUpload({ commit }, payload: EditorUpload) {
    commit('insertOrUpdateEditorUpload', payload);
  },
  removeEditorUpload({ commit }, payload: string) {
    commit('removeEditorUpload', payload);
  },
} as ActionTree<EditorState, any>;
