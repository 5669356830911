export function browserStorageSetItem<T = void>(itemName: string, value: any): void {
  const valueToStore = typeof value === 'string' ? value : JSON.stringify(value);
  window.localStorage.setItem(itemName, valueToStore);
}

export function browserStorageGetItem<T = void>(
  itemName: string & (T extends void ? 'Specify return type of the function call' : string)
): T | null {
  const item = window.localStorage.getItem(itemName);

  if (item === null || item === 'undefined') {
    return null;
  }

  try {
    return JSON.parse(item) as T;
  } catch (_) {
    // localStorage.getItem() returns string always,
    // but in this case we want to say that we know it is NOT string
    return item as unknown as T;
  }
}

export function browserStorageRemoveItem(key: string): void {
  window.localStorage.removeItem(key);
}

/**
 * Function is used to get/set item from/to localStorage, where item is an object
 * @param itemName - name of the item to get/set from/to localStorage
 * @param key - key of the item to set in localStorage
 * @param value - value to set
 */
export function browserStorageGetAndSetItem(itemName: string, key: string, value: any): void {
  const item: any = browserStorageGetItem(itemName) || {};
  item[key] = value;
  browserStorageSetItem(itemName, item);
}

/**
 * Function is used to rename an item in localStorage
 * @param oldKey - old key of the item to rename
 * @param newKey - new key of the item
 * @returns void
 */
export function browserStorageRenameItem(oldKey: string, newKey: string): void {
  const item: any = browserStorageGetItem(oldKey) || {};
  browserStorageSetItem(newKey, item);
  browserStorageRemoveItem(oldKey);
}

/**
 * Function is used to remove multiple items from localStorage by keys
 * @param keys - array of keys to remove
 * @returns void
 */
export function removeMultipleItemsByKeys(keys: string[]): void {
  keys.forEach((key) => {
    window.localStorage.removeItem(key);
  });
}

/**
 * Function is used to remove multiple items from localStorage by prefix
 * @param prefix - prefix of the items to remove
 * @returns void
 */
export function removeMultipleItemsByPrefix(prefix: string): void {
  Object.keys(window.localStorage).forEach((key) => {
    if (key.startsWith(prefix)) {
      window.localStorage.removeItem(key);
    }
  });
}

/**
 * Function is used to remove multiple items from localStorage by array of prefixes
 * @param prefixes - array of prefixes of the items to remove
 * @returns void
 * */
export function removeMultipleItemsByArrayPrefix(prefixes: string[]): void {
  Object.keys(window.localStorage).forEach((key) => {
    prefixes.forEach((prefix) => {
      if (key.startsWith(prefix)) {
        window.localStorage.removeItem(key);
      }
    });
  });
}
