import Vue from 'vue';
import * as Sentry from '@sentry/vue';
const isLocalhost = window.location.hostname === 'localhost';
const isEnvDevOrLocal = ['dev', 'local'].includes(`${process.env.VUE_APP_ENV}`);

const initSentry = (vueRouterRef: any) => {
  if (!isLocalhost && !isEnvDevOrLocal) {
    Sentry.init({
      Vue,
      dsn: 'https://c872c89d21344a3bbd8f18732ac6713b@o340344.ingest.sentry.io/5444532',
      integrations: [
        Sentry.browserTracingIntegration({
          ...(vueRouterRef && { router: vueRouterRef }),
        }),
      ],
      tracesSampleRate: 0.8,
      environment: process.env.VUE_APP_ENV,
    });
  }
};

const logError = (error: any) => Sentry.captureException(error);

export { logError, initSentry };
