import EditorService from '@/components/editor/services/EditorService';
import { ResourceType } from '@/types/resources';
import { EditorUpload } from './types';

export type { EditorService };
export interface EditorState {
  editors: { [id: string]: EditorService };
  currentEditorId?: string;
  currentEditorResourceType?: ResourceType;
  persistedPageKeys?: Array<string>;
  suggesterShown?: boolean;
  uploading: EditorUpload[];
}

export default {
  editors: {},
  currentEditorId: undefined,
  currentEditorResourceType: undefined,
  persistedPageKeys: [],
  suggesterShown: false,
  uploading: [],
} as EditorState;
