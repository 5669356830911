import { saveAs } from 'file-saver';
import isFirefox from './isFirefox';

export function downloadFile(url, name) {
  name = name || 'untitled';

  /*
   * TODO: https://app.superthread.com/superthread/card-8409-yjs-ws-connections-dont-close-correctly
   * firefox has a beforeunload event that prevents normal download
   * so we need to use file-saver instead
   *
   * TODO: https://app.superthread.com/superthread/card-9146-add-download-links-for-unsplash-images
   * unsplash images are not downloadable natively as they are missing the content-disposition header
   */
  if (isFirefox() || url.startsWith('https://images.unsplash.com')) {
    saveAs(url, name);
    return;
  }

  // create temporary link to mimic download
  // we cannot use the <a> tag directly in components
  // since we need to handle the firefox use case
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
