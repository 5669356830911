/* eslint-disable no-shadow */
/**
 * Page module vuex getters
 * @packageDocumentation
 * @category Store
 */
import { GetterTree } from 'vuex';
import { PageState as State, Page } from '@/types';

const getters: GetterTree<State, any> = {
  archivedPages: (state) => state.archivedPages,

  getCurrentPageID: (state) => state.currentPageID || state.page.id,

  getCurrentPageMode: (state) => state.currentPageMode,

  getPageTitle: (state) => state.page.title,

  getPage: (state) => state.page,

  getCurentPage: (state) => state.pages[state.currentPageID || ''] || {},

  getPageContent: (state) => state.page.content,

  loadingPages: (state) => state.loadingPages,

  getPageListOrderBy: (state) => state.pageListOrderBy,

  getPagesEntity: (state) => state.pagesEntity,

  pageCreating: (state) => state.pageCreating,

  showImageUploadPopup: (state) => state.imageUploadPopup,

  showCoverImageUploadPopup: (state) => state.coverImageUploadPopup,

  showPageIconUploadPopup: (state) => state.pageIconUploadPopup,

  showInsertLinkPopup: (state) => state.insertLinkPopup,

  pageVersions: (state) => state.pageVersions,

  getCurrentProjectPages: (state, _getters, rootState, rootGetters) =>
    state.projectPages[rootGetters.getCurrentProjectId] || [],

  getPageById: (state) => (pageId: string) => state.pages[pageId],

  getPages: (state) => state.pages,

  getNewPageCreating: (state) => state.newPageCreating,

  commentPopupVisible: (state) => state.inlineCommentPopup,

  getProjectPages: (state) => (projectId: string) => state.projectPages[projectId] || [],

  lastSidebarItemAdded: (state) => state.lastSidebarItemAdded || '',

  getParentPageIdForAdd: (state) => state.parentPageIdForAdd,

  /** Returns the pages tree for specific projectId. */
  getProjectPagesTree: (state) => (projectId: string | number) =>
    state.projectPagesTree[projectId] || {},

  getProjectPagesArchiveTree: (state) => (projectId: string | number) =>
    state.archivePagesTree[projectId] || {},

  sidebarDraftPage: (state) => state.sidebarDraftPage || {},

  getParentPageExpanded: (state) => (id: string) => state.parentPageExpanded[id] || false,

  getPageUploadPopup: (state) => state.pageUploadPopup,
  pageRenamingId: (state) => state.pageRenamingId,
  commentsSchema: (state) => state.commentsSchema,
  pagesSchema: (state) => state.pagesSchema,

  getQuickPageFilesToUpdate: (state) => state.quickPageFilesToUpdate,

  archivePages: (state) => state.archivePages,

  highlightedPageLevelComments: (state) => state.highlightedPageLevelComments,

  getPageLevelCommentInputActive: (state) => state.pageLevelCommentInputActive,

  getPageTemplates: (state) => state.pageTemplates,
  getPageTemplateDraft: (state) => state.pageTemplateDraft,

  getPageEditorDisplay: (state) => state.pageEditorDisplay,

  getPageIdForMove: (state) => state.pageIdForMove,
};

export default getters;
