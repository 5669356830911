const icons = [
  'headphones',
  'activity',
  'alert-circle',
  'anchor',
  'award',
  'bell',
  'box',
  'briefcase',
  'camera',
  'check-circle',
  'clock',
  'coffee',
  'compass',
  'credit-card',
  'disc',
  'feather',
  'folder',
  'flag',
  'gift',
  'globe',
  'hash',
  'heart',
  'hexagon',
  'home',
  'image-st',
  'inbox',
  'info',
  'key',
  'life-buoy',
  'link-st',
  'loader',
  'lock',
  'mail',
  'map',
  'map-pin',
  'meh',
  'message-circle',
  'mic',
  'moon',
  'music',
  'paperclip',
  'rocket',
  'shopping-bag',
  'target',
  'shield',
  'thumbs-up',
  'thumbs-down',
  'tool',
  'trending-up',
  'truck',
  'tv',
  'umbrella',
  'watch',
  'video-st',
  'zap',
];

const colors = [
  '#99CDFF',
  '#FDA29B',
  '#FEC84B',
  '#6CE9A6',
  '#7CD4FD',
  '#A4BCFD',
  '#BDB4FE',
  '#FAA7E0',
  '#FEB273',
  '#475467',
];

const appColors = {
  warning300: '#FEC84B',
  gray300: '#D0D5DD',
  gray600: '#475467',
  gray900: '#101828',
  error600: '#D92D20',
};

const predefinedListNames = ['todo', 'to do', 'qa', 'q&a', 'doing', 'backlog', 'ideas', 'done'];

export { icons, colors, predefinedListNames, appColors };
