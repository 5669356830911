import { OnboardingState as IState } from '@/types';

const getDefaultOnboardingState = (): IState => ({
  currentForm: 'login_register',
  userID: '',
  teamName: '',
  subdomain: '',
  invitationCode: '',
  errorMessage: '',
  projectName: '',
  currentTeamId: '',
  currentTeamName: '',
  workspaceErrorMessage: '',
  failedInvites: [],
  onboardingWorkspace: JSON.parse(localStorage['onboardingWorkspace'] || '{}'),
  signupAnswers: {},
  getStartedExpanded: true,
  getStartedShowAll: false,
  onboardingActions: [],
});

export default getDefaultOnboardingState;
