import axios, { AxiosError } from 'axios';
import store from '@/store';
import { Api, HttpClient } from '@superthread-com/api';

const defaultConfig = () => ({
  withCredentials: true,
  withXSRFToken: true,
});

const httpClient = new HttpClient({
  timeout: 20000,
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/v1`,
  ...defaultConfig(),
});

const http = httpClient.instance;
const { get: axiosBaseGet } = axios;

let refereshPromise: any = null;
let refreshPending: boolean = false;

const authRefresh = (client: any = http) => {
  if (refreshPending) return refereshPromise;

  refreshPending = true;
  refereshPromise = client
    .post('auth/refresh', {}, { ...defaultConfig() })
    .then((res: any) => {
      refreshPending = false;
      return res;
    })
    .catch((err: any) => {
      refreshPending = false;
      return Promise.reject(err);
    });
  return refereshPromise;
};

const createAxiosResponseInterceptor = (client: any) => {
  const interceptor = client.interceptors.response.use(
    (response: any) => response,
    (errorResponse: any) => {
      if (axios.isCancel(errorResponse)) {
        return Promise.reject(errorResponse.message);
      }
      if (errorResponse.response?.status === 401) {
        /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        client.interceptors.response.eject(interceptor);

        return authRefresh(client)
          .then((response: Response) => {
            const { config } = errorResponse.response;
            // Retry
            return client({
              method: config.method,
              url: config.url,
              data: config.data,
              params: config.params,
              ...defaultConfig(),
            });
          })
          .catch((error: AxiosError) => {
            const { pathname } = window.location;
            if (pathname !== '/login' && pathname !== '/' && error?.response) {
              if (store.getters.getLoggedIn) {
                window.location.href = '/login';
              } else {
                window.history.pushState({}, '', '/login');
              }
            }
            return Promise.reject(error);
          })
          .finally(() => createAxiosResponseInterceptor(client));
      }
      return Promise.reject(errorResponse);
    }
  );
};

createAxiosResponseInterceptor(http);

const api = new Api(httpClient);

const baseGet = (endpoint = '', params = {}) => http.get(endpoint, { params });

const authGet = (endpoint = '', customConfig?: any) =>
  http.get(endpoint, { ...defaultConfig(), ...customConfig });

const authPut = (endpoint = '', putData = {}, customConfig?: any) =>
  http.put(endpoint, putData, { ...defaultConfig(), ...customConfig });

const basePost = (endpoint = '', postData = {}) => http.post(endpoint, postData);

const authPost = (endpoint = '', postData = {}, customConfig?: any) =>
  http.post(endpoint, postData, { ...defaultConfig(), ...customConfig });

const authPatch = (endpoint = '', patchData = {}, customConfig?: any) =>
  http.patch(endpoint, patchData, { ...defaultConfig(), ...customConfig });

const authDelete = (endpoint = '', customConfig?: any) =>
  http.delete(endpoint, { ...defaultConfig(), ...customConfig });

export {
  authGet,
  authPut,
  authPost,
  authPatch,
  baseGet,
  basePost,
  authDelete,
  authRefresh,
  axiosBaseGet,
  http,
  api,
};
