export default {
  data() {
    return {
      filteredImages: [],
    };
  },
  computed: {
    currentPageID() {
      return this.$store.getters.getCurrentPageID;
    },

    teamId() {
      return this.$store.getters.getTeamID;
    },

    currentUserId() {
      return this.$store.getters.getUser.id;
    },

    isPage() {
      return this.$route.name === 'page';
    },
  },

  methods: {
    unsplashObjectByImgSrc(url) {
      const unsplashId = url ? url.substring(url.indexOf('.com/') + 5, url.lastIndexOf('?')) : '';
      const img = {
        id: `unsplash-${unsplashId}`,
        name: this.translate('unsplashImage'),
        page_id: this.currentPageID,
        team_id: this.teamId,
        time_created: Math.floor(Date.now() / 1000),
        type: 'image/jpg',
        url,
        user_id: this.currentUserId,
      };
      return img;
    },

    objectByImgSrc(id, url) {
      return {
        id,
        name: '',
        team_id: this.teamId,
        time_created: Math.floor(Date.now() / 1000),
        type: 'image/jpg',
        url,
        user_id: this.currentUserId,
      };
    },

    filterImagesByEditorId(editorId = this.editorId) {
      this.filteredImages = [];
      const editor = document.querySelector(`div[id="${editorId || 'page-markdown-direct'}"`);
      const allEditorImages = editor.querySelectorAll(this.$constants.stImage);
      // select all images from current editor using editor id
      allEditorImages.forEach((image) => {
        const url = image.attributes.imgsrc.nodeValue;
        // check if it is unsplash image
        if (image.imgsrc.includes(this.$constants.UNSPLASH_BASE_URL)) {
          const unsplashImg = this.unsplashObjectByImgSrc(url);
          this.filteredImages.push(unsplashImg);
        } else {
          // get file id from url
          const urlFiles = 'files/';
          const id = url.substring(url.lastIndexOf(urlFiles) + urlFiles.length, url.length);
          let imgObject = this.$store.getters.getLoadedFiles.find((a) => a.id === id);
          // declare imgObject variable and assign to it file from state if any
          if (!imgObject) {
            // if file not on the state generete hardcoded object
            imgObject = this.objectByImgSrc(id, url);
            this.filteredImages.push(imgObject);
            this.$store.dispatch('getFile', id).then((data) => {
              // get file from response and replace hardcoded ones with it
              this.filteredImages.forEach((i, index) => {
                if (i.id === id) {
                  this.filteredImages[index] = data;
                }
              });
            });
          } else {
            this.filteredImages.push(imgObject);
          }
        }
      });
    },

    filterImages(data) {
      const images = [];
      const unsplashImages = document.querySelectorAll(
        `${this.$constants.stImage}[imgsrc^="${this.$constants.UNSPLASH_BASE_URL}"]`
      );
      if (unsplashImages) {
        unsplashImages.forEach((i) => {
          const img = this.unsplashObjectByImgSrc(i.attributes.imgsrc.nodeValue);
          images.push(img);
        });
      }
      data.forEach((i) => {
        if (i) {
          images.push(i);
        }
      });
      return images;
    },

    showEmbedFullScreen(id, editorId) {
      this.filterImagesByEditorId(editorId);
      if (editorId && this.editorId && editorId !== this.editorId) return;
      const attachment = this.filteredImages.find((a) => a.id === id);
      this.$store.dispatch('setPreviewAttachments', this.filteredImages);
      this.$store.dispatch('setAttachmentPreviewFromInput', true);
      if (!attachment) {
        if (id.startsWith('unsplash')) {
          this.setPreviewAttachmentId(id);
          return;
        }
        this.$store.dispatch('getFile', id).then((file) => {
          if (!file.id) return;
          this.$store.dispatch('setPreviewAttachments', [...this.filteredImages, file]);
          this.setPreviewAttachmentId(file.id);
        });
        return;
      }
      this.setPreviewAttachmentId(attachment.id);
    },

    setPreviewAttachmentId(id) {
      this.$store.dispatch('setPreviewAttachmentId', {
        attachmentId: id,
      });
    },
  },
};
