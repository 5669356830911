/**
 * Onboardig module mutations
 * @packageDocumentation
 * @category Store
 */
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { OnboardingState as State, Team } from '@/types';
import getDefaultOnboardingState from './state';
import { OnboardingAction, OnboardingActionStatus } from '@/types/onboardingActions';

const mutations: MutationTree<State> = {
  setOnboardingCurrentForm(state, currentForm: string) {
    state.currentForm = currentForm;
  },

  setOnboardingUserID(state, userID: string) {
    state.userID = userID;
  },

  setOnboardingWorkspace(state, value?: Team) {
    if (value) {
      if (value.team_name && !value.sub_domain) {
        value.sub_domain = value.team_name.replace(/[\W_]+/g, '').toLowerCase();
      }

      state.onboardingWorkspace = Object.assign({}, state.onboardingWorkspace, value);
      localStorage.setItem('onboardingWorkspace', JSON.stringify(state.onboardingWorkspace));
    } else {
      state.onboardingWorkspace = {};
      localStorage.removeItem('onboardingWorkspace');
    }
  },

  setOnboardingErrorMessage(state, errorMessage: string) {
    state.errorMessage = errorMessage;
  },
  setWorkspaceErrorMessage(state, workspaceErrorMessage: string) {
    state.workspaceErrorMessage = workspaceErrorMessage;
  },

  setInvitationCode(state, invitationCode: string) {
    state.invitationCode = invitationCode;
  },

  setCurrentTeamId(state, teamId: string) {
    state.currentTeamId = teamId;
    localStorage.setItem('currentTeamId', teamId);
  },

  setCurrentTeamName(state, teamName: string) {
    state.currentTeamName = teamName;
  },

  setFailedInvites(state, failedInvites: string[]) {
    state.failedInvites = failedInvites;
  },

  resetOnboardingState(state) {
    Object.assign(state, getDefaultOnboardingState());
  },

  storeSignupAnswers(state, answers) {
    Object.assign(state.signupAnswers, answers);
  },

  setGetStartedExpanded(state, value) {
    localStorage.setItem('getStartedExpanded', value);
    state.getStartedExpanded = value;
  },

  setGetStartedShowAll(state, value) {
    localStorage.setItem('getStartedShowAll', value);
    state.getStartedShowAll = value;
  },

  setOnboardingActions(state, value) {
    state.onboardingActions = value;
  },

  completeOnboardingActions(state, actions) {
    actions.forEach((a: OnboardingAction) => {
      const index = state.onboardingActions.findIndex((sa: OnboardingAction) => sa.type === a.type);

      // set flag indicating that animation should be triggered
      Vue.set(state.onboardingActions, index, {
        ...state.onboardingActions[index],
        animate_completion: true,
      });

      setTimeout(() => {
        // check if action has been dismissed during animation
        if (state.onboardingActions[index]?.status === OnboardingActionStatus.Dismissed) return;

        // update action with data from response and clear animation flag
        // action is now fully completed
        Vue.set(state.onboardingActions, index, a);
      }, 2000);
    });
  },

  updateOnboardingActionsStatus(state, actions) {
    actions.forEach((a: OnboardingAction) => {
      const index = state.onboardingActions.findIndex((sa: OnboardingAction) => sa.type === a.type);
      Vue.set(state.onboardingActions, index, a);
    });
  },
};

export default mutations;
