import { userHashColor } from '@/utilities/composables/useUserHashColor';
import generateSrcUrl from '@/utilities/generateSrcUrl';

export default {
  computed: {
    userId() {
      return this.member.id || this.member.user_id || this.member.invitee_user_id || '';
    },
    storeMember() {
      return this.$store.getters.getMemberById(this.userId);
    },
    hidden() {
      return this.isGuestRole && Object.keys(this.storeMember).length === 0;
    },
    hiddenStyle() {
      return {
        backgroundColor: this.member.color ?? userHashColor(this.userId),
        maskPosition: 'center',
      };
    },
    memberInTeamMembers() {
      if (this.hidden) return {};
      return {
        ...this.storeMember,
        color: this.storeMember.color ?? userHashColor(this.storeMember),
      };
    },
    memberInitials() {
      if (this.hidden) return '';
      const { first_name, last_name, display_name, email } = this.memberInTeamMembers;
      const name =
        first_name || last_name || display_name || email || this.member.invitee_email || '';
      return name.substring(0, 1).toUpperCase();
    },
    fallbackStyle() {
      if (this.hidden)
        return {
          borderColor: `${this.member.color ?? userHashColor(this.userId)} !important`,
          backgroundColor: 'var(--background)',
        };
      const { thumbnail_image, profile_image } = this.memberInTeamMembers;
      const img = thumbnail_image || profile_image;
      return {
        backgroundColor: this.memberInTeamMembers.color || '#212121',
        ...(img && {
          backgroundImage: `url("${generateSrcUrl(img, 48, 48, true)}")`,
          backgroundPosition: 'center',
        }),
        ...(this.showUserColor && {
          border: `2px solid ${this.memberInTeamMembers.color}`,
        }),
      };
    },
    memberColor() {
      return this.showUserColor ? this.memberInTeamMembers.color : '';
    },
  },
};
