import { GetterTree } from 'vuex';
import { EditorState, EditorService } from './state';

export default {
  editor:
    (state) =>
    (id: string): EditorService => {
      return state.editors[id];
    },

  currentEditor: (state): EditorService | void => {
    if (state.currentEditorId) {
      return state.editors[state.currentEditorId];
    }
  },

  getPersistedPagesKeys: (state) => state.persistedPageKeys || [],

  checkIfDbExists:
    (state) =>
    (dbName: string): boolean =>
      state.persistedPageKeys?.includes(dbName) || false,

  suggesterShown: (state) => state.suggesterShown,
} as GetterTree<EditorState, any>;
