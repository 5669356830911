import Vue from 'vue';
import Router from 'vue-router';
import { translate } from '@/utilities';
import constants from '@/utilities/constants';
import store from '../store';
import { authRefresh } from '../utilities/api';
import authentication from './modules/authentication';
import workspaces from './modules/workspaces';
import { beforeEachGuard, afterEachGuard } from './utilities';
import { cardBeforeEnterHandler } from './utilities/cardRouteHandler';
import EventBus from '../utilities/eventBus';
import checkRole from './utilities/checkRole';
import boardViewBeforeEnter from '@/router/utilities/boardViewBeforeEnter';
import { TeamMemberRole } from '@superthread-com/api';
import { EpicTabId } from '@/types/tabs';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      name: 'notfound',
      component: () =>
        import(/* webpackChunkName: "404notfound" */ '../components/pages/NotFound.vue'),
    },

    // APP //
    {
      path: '',
      name: 'home',
    },

    ...authentication,
    ...workspaces,

    {
      path: '/integrations',
      name: 'integrations',
      component: () =>
        import(/* webpackChunkName: "integrations" */ '../components/pages/Integrations.vue'),
      beforeEnter: (to, _from, next) => {
        if (to.query.state) {
          const integration: any = to.query.type;
          store
            .dispatch('createInstallationForIntegration', {
              integration,
              payload: {
                [integration]: {
                  id: to.query.installation_id,
                },
              },
            })
            .then(() => {
              store.dispatch('addUiNotification', {
                message: translate('gitHubConnected'),
                duration: 5000,
              });
            });
          const workspaces = store.getters.getUser.teams;
          const workspace = workspaces.find((ws: any) => ws.id === to.query.state);
          const defaultTabName = store.getters.getDefaultTabName;

          if (workspace) {
            store.dispatch('setCurrentTeamId', workspace.id);
            store.dispatch('setTeamSubDomain', workspace.sub_domain);
            const key = `${workspace.id}-currentProjectId`;
            const currentProjectIdForWs = localStorage.getItem(key);
            if (currentProjectIdForWs) {
              store.dispatch('setCurrentProjectId', currentProjectIdForWs);
            }
            store.dispatch('setOnboardingCurrentForm', '');
            store.dispatch('fetchTeamMembers').catch(() => {});
            router.push(defaultTabName);
          }
        }
        next();
      },
    },

    {
      path: '/:team_subdomain/login/',
      name: constants.routeNames.teamLogin,
      component: () =>
        import(
          /* webpackChunkName: "loginOnboarding" */ '../components/pages/authentication/Login.vue'
        ),
      meta: { requiresAuth: false },
    },

    {
      // settings without workspace, navigate to current workspace
      path: '/settings*',
      redirect: (to) => {
        const teamSlug = store.getters.getTeamSlug;
        return { path: `/${teamSlug}${to.path}`, query: to.query };
      },
    },

    {
      // inbox without workspace, navigate to current workspace
      path: '/inbox*',
      redirect: (to) => {
        const teamSlug = store.getters.getTeamSlug;
        return { path: `/${teamSlug}${to.path}`, query: to.query };
      },
    },

    {
      path: '/:team_subdomain/settings',
      name: 'settings',
      component: () => import(/* webpackChunkName: "settings" */ '../settings/pages/Settings.vue'),
      redirect: () => {
        const teamSlug = store.getters.getTeamSlug;
        return { path: `/${teamSlug}/settings/profile` };
      },
      beforeEnter: (_to, from, next) => {
        EventBus.$emit('closeCard');
        store.dispatch('setRouteBeforeSettings', from.path);
        next();
      },
      children: [
        {
          path: 'integration',
          name: 'integration',
          component: () =>
            import(/* webpackChunkName: "settings" */ '@/settings/pages/Integration.vue'),
          redirect: () => {
            const teamSlug = store.getters.getTeamSlug;
            return { path: `/${teamSlug}/settings/integration/github` };
          },
          children: [
            {
              path: 'github',
              name: 'github',
              component: () =>
                import(/* webpackChunkName: "settings" */ '@/settings/pages/GithubIntegration.vue'),
            },
            {
              path: 'zapier',
              name: 'zapier',
              component: () =>
                import(/* webpackChunkName: "settings" */ '@/settings/pages/ZapierIntegration.vue'),
            },
            {
              path: 'slack',
              name: 'slack',
              component: () =>
                import(/* webpackChunkName: "settings" */ '@/settings/pages/SlackUnfurling.vue'),
              beforeEnter: (to, _from, next) => {
                const integration = 'slack_team_unfurl';
                const teamSlug = store.getters.getTeamSlug;

                const state = localStorage.getItem('slackOAuthState');
                localStorage.removeItem('slackOAuthState');
                if (to.query.code && to.query.state === state) {
                  const redirectUri = `${window.location.origin}/${teamSlug}/settings/integration/slack`;
                  store
                    .dispatch('createInstallationForIntegration', {
                      integration,
                      payload: {
                        slack_team_unfurl: {
                          code: to.query.code,
                          redirect_uri: redirectUri,
                        },
                      },
                    })
                    .then(() => {
                      store
                        .dispatch('getTeamIntegrationInstallations', {
                          integration,
                        })
                        .then(() => {
                          router.push(`/${teamSlug}/settings/integration/slack`);
                          next();
                        });
                    })
                    .catch(() => {
                      next();
                    });
                } else {
                  store
                    .dispatch('getTeamIntegrationInstallations', {
                      integration,
                    })
                    .then(() => {
                      next();
                    })
                    .catch(() => {
                      next();
                    });
                }
              },
            },
          ],
        },
        {
          path: 'tags',
          name: 'tags',
          component: () =>
            import(/* webpackChunkName: "settings" */ '@/settings/pages/WorkspaceTags.vue'),
          beforeEnter: (to, from, next) => {
            store
              .dispatch('fetchTags', {
                projectId: store.getters.getCurrentProjectId,
              })
              .then(() => {
                next();
              });
          },
        },
        {
          path: constants.settingsTemplate.root,
          component: () =>
            import(/* webpackChunkName: "settings" */ '@/settings/pages/WorkspaceTemplates.vue'),
          children: [
            {
              path: '',
              redirect: constants.settingsTemplate.card.root.path,
            },
            {
              path: constants.settingsTemplate.card.root.path,
              name: constants.settingsTemplate.card.root.name,
              component: () =>
                import(
                  /* webpackChunkName: "settings" */ '@/settings/pages/WorkspaceTemplateContent.vue'
                ),
              children: [
                {
                  path: constants.settingsTemplate.card.create.path,
                  name: constants.settingsTemplate.card.create.name,
                },
                {
                  path: `${constants.settingsTemplate.card.edit.path}/:templateId`,
                  name: constants.settingsTemplate.card.edit.name,
                },
              ],
            },
            {
              path: constants.settingsTemplate.page.root.path,
              name: constants.settingsTemplate.page.root.name,
              component: () =>
                import(
                  /* webpackChunkName: "settings" */ '@/settings/pages/WorkspaceTemplateContent.vue'
                ),
              children: [
                {
                  path: constants.settingsTemplate.page.create.path,
                  name: constants.settingsTemplate.page.create.name,
                },
                {
                  path: `${constants.settingsTemplate.page.edit.path}/:templateId`,
                  name: constants.settingsTemplate.page.edit.name,
                },
              ],
            },
          ],
        },
        {
          path: 'members',
          name: 'members',
          component: () =>
            import(/* webpackChunkName: "settings" */ '@/settings/pages/MembersManagement.vue'),
          beforeEnter: (to, from, next) => {
            if (from.name) {
              authRefresh();
              next();
            } else {
              Promise.all([
                store.dispatch('fetchTeamMembers').catch(() => {}),
                authRefresh(),
                store.dispatch('getTeamPendingInvities'),
              ]).then(() => {
                next();
              });
            }
          },
        },
        {
          path: 'general',
          name: 'general',
          component: () =>
            import(/* webpackChunkName: "settings" */ '@/settings/pages/WorkspaceManagement.vue'),
        },
        {
          path: 'import',
          name: 'import',
          component: () => import(/* webpackChunkName: "settings" */ '@/settings/pages/Import.vue'),
          beforeEnter: (to, from, next) => {
            store
              .dispatch('getImportHistory')
              .then((data) => {
                store.dispatch('setImportHistory', data);
                next();
              })
              .catch(() => {
                next();
              });
          },
          children: [
            {
              path: 'trello',
              name: 'trello',
              component: () =>
                import(/* webpackChunkName: "settings" */ '@/settings/pages/ImportSetup.vue'),
            },
            {
              path: 'jira',
              name: 'jira',
              component: () =>
                import(/* webpackChunkName: "settings" */ '@/settings/pages/ImportSetup.vue'),
            },
            {
              path: 'shortcut',
              name: 'shortcut',
              component: () =>
                import(/* webpackChunkName: "settings" */ '@/settings/pages/ImportSetup.vue'),
            },
          ],
        },
        {
          path: 'billing',
          name: 'billing',
          redirect: () => {
            const teamSlug = store.getters.getTeamSlug;
            return { path: `/${teamSlug}/settings/billing/plan` };
          },
          component: () =>
            import(/* webpackChunkName: "settings" */ '@/settings/pages/PlanAndBilling.vue'),
          children: [
            {
              path: 'plan',
              name: 'plan',
              beforeEnter: (_to, _from, next) => {
                const teamSlug = store.getters.getTeamSlug;
                next(
                  checkRole(
                    store,
                    {
                      pass: undefined,
                      fail: { path: `/${teamSlug}/settings` },
                    },
                    { allowed: ['owner', 'admin'] }
                  )
                );
              },
              component: () =>
                import(/* webpackChunkName: "settings" */ '@/settings/pages/Plan.vue'),
            },
            {
              path: 'payment',
              name: 'payment',
              beforeEnter: (_to, _from, next) => {
                const teamSlug = store.getters.getTeamSlug;
                next(
                  checkRole(
                    store,
                    {
                      pass: undefined,
                      fail: { path: `/${teamSlug}/settings` },
                    },
                    { allowed: ['owner', 'admin'] }
                  )
                );
              },
              component: () =>
                import(/* webpackChunkName: "settings" */ '@/settings/pages/Billing.vue'),
            },
          ],
        },
        {
          path: 'profile',
          name: 'profile',
          component: () =>
            import(/* webpackChunkName: "settings" */ '@/settings/pages/EditUserProfile.vue'),
        },
        {
          path: 'preferences',
          name: 'preferences',
          component: () =>
            import(/* webpackChunkName: "settings" */ '@/settings/pages/Preferences.vue'),
        },
        {
          path: 'manage-spaces',
          name: constants.settingsManageSpacesRouteName,
          component: () =>
            import(/* webpackChunkName: "settings" */ '@/settings/pages/ManageProjectsContent.vue'),
          beforeEnter: (_, __, next) => {
            if (store.getters.getCurrentProjectId) {
              store.dispatch('setCurrentProjectId', '');
            }
            next();
          },
        },
        {
          path: 'create-space',
          name: constants.settingsCreateSpaceRouteName,
          component: () =>
            import(/* webpackChunkName: "settings" */ '@/settings/pages/CreateProjectContent.vue'),
        },
        {
          path: `${constants.projectSlug}-:projectIdParam`,
          name: constants.settingsSpaceRouteName,
          component: () =>
            import(/* webpackChunkName: "settings" */ '@/settings/pages/SettingsProjects.vue'),
          beforeEnter: (to, _from, next) => {
            const projectId = to.params.projectIdParam;
            if (projectId !== store.getters.getSettingsCurrentProjectId)
              store.dispatch('setSettingsCurrentProjectId', projectId);
            next();
          },
          children: [
            {
              path: `boards`,
              name: constants.settingsBoardsRouteName,
              component: () =>
                import(/* webpackChunkName: "settings" */ '@/settings/pages/BoardsSettings.vue'),
              beforeEnter: async (to, _from, next) => {
                const gitHubIntegrated =
                  store.getters.getIntegrationInstallations('github').length > 0;
                if (!gitHubIntegrated) {
                  store.dispatch('getTeamIntegrationInstallations', {
                    integration: 'github',
                  });
                }
                store.dispatch('getTeamIntegrationInstallations', {
                  integration: constants.webhookIntegrations.slackChannel,
                });

                const projectId = to.params?.projectIdParam;
                if (projectId) {
                  const sprint = await store.dispatch('getSprintSettings', projectId);
                  store
                    .dispatch('loadBoards', projectId)
                    .then(({ board_order: projectBoards }) => {
                      store.dispatch('setProjectPreviewBoards', {
                        projectBoards,
                        projectId,
                      });
                      const [firstBoardId] = projectBoards ?? [];
                      if (store.getters.getSettingsCurrentBoardId === -1) {
                        store.dispatch('setSettingsCurrentBoardId', firstBoardId);
                      }

                      if (to?.name === constants.settingsBoardsRouteName) {
                        if (sprint.enabled && !to.params.boardId) {
                          next({
                            path: `${to.path}/sprints`,
                          });
                        } else if (projectBoards.length && !sprint.enabled) {
                          next({
                            path: `${to.path}/${firstBoardId}`,
                          });
                        }
                      }
                      next();
                    })
                    .catch((_e) => {
                      // console.log(e)
                    });
                }
              },
              children: [
                {
                  path: ':boardId',
                  name: constants.settingsSelectedBoardRouteName,
                  component: () =>
                    import(
                      /* webpackChunkName: "settings" */ '@/settings/pages/BoardsSettings.vue'
                    ),
                  beforeEnter: (_to, _from, next) => {
                    const boardId = _to.params?.boardId;
                    const projectId = _to.params?.projectIdParam;

                    const slackChannelWebhookIntegrated = store.getters
                      .getIntegrationInstallations(constants.webhookIntegrations.slackChannel)
                      .find((i: any) => i.slack_channel_webhook?.board_id === boardId);

                    if (!slackChannelWebhookIntegrated) {
                      store.dispatch('getTeamIntegrationInstallations', {
                        integration: constants.webhookIntegrations.slackChannel,
                      });
                    }

                    if (projectId) {
                      store
                        .dispatch('loadBoards', projectId)
                        .then(({ board_order: projectBoards }) => {
                          if (!projectBoards.includes(boardId) && boardId !== 'sprints') {
                            next({
                              name: constants.settingsBoardsRouteName,
                              params: {
                                ..._to.params,
                              },
                            });
                            store.dispatch('addUiNotification', {
                              message: translate('boardDoesNotExists'),
                              status: constants.uiNotificationStatuses.error,
                            });
                          }
                        });
                    }

                    next();
                  },
                  children: [
                    {
                      path: 'slack',
                      beforeEnter: (_to, _from, next) => {
                        const integration = constants.webhookIntegrations.slackChannel;
                        const teamSlug = store.getters.getTeamSlug;
                        const projectId = _to.params.projectIdParam;
                        const boardId = _to.params.boardId;
                        const slackCode = _to.query.code;
                        const slackState = _to.query.state;

                        const state = localStorage.getItem('slackOAuthState');
                        localStorage.removeItem('slackOAuthState');
                        if (slackCode && state === slackState) {
                          const webhookInstallation = {
                            integration,
                            payload: {
                              slack_channel_webhook: {
                                code: slackCode,
                                board_id: boardId,
                                redirect_uri: `${window.location.origin}/${teamSlug}/settings/${constants.projectSlug}-${projectId}/boards/${boardId}/slack`,
                              },
                            },
                          };

                          store
                            .dispatch('createInstallationForIntegration', webhookInstallation)
                            .then(({ data }) => {
                              store.dispatch('createBoardWebhookNotification', {
                                boardId,
                                integration,
                                integrationId: data.id,
                              });
                            })
                            .then(() => {
                              store.dispatch('getTeamIntegrationInstallations', {
                                integration: constants.webhookIntegrations.slackChannel,
                              });
                            })
                            .catch((_e) => {
                              // console.log(_e);
                              store.dispatch('addUiNotification', {
                                message: translate('slackIntegrationError'),
                                status: constants.uiNotificationStatuses.error,
                              });
                            });

                          next({
                            name: constants.settingsSelectedBoardRouteName,
                            params: _to.params,
                          });
                        } else {
                          store.dispatch('addUiNotification', {
                            message: translate('slackIntegrationError'),
                            status: constants.uiNotificationStatuses.error,
                          });
                          next({
                            name: constants.settingsSelectedBoardRouteName,
                            params: _to.params,
                          });
                        }
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: `sprints`,
              name: constants.settingsSprintsRouteName,
              component: () =>
                import(/* webpackChunkName: "settings" */ '@/settings/pages/SprintsSettings.vue'),
            },
          ],
        },
        {
          path: 'notifications',
          name: 'notifications',
          component: () =>
            import(
              /* webpackChunkName: "settings" */ '@/settings/pages/NotificationsIntegrations.vue'
            ),
          beforeEnter: (to, from, next) => {
            // load notification digest
            store.dispatch('fetchNotificationDigest').then(() => {
              store.dispatch('fetchNotificationDigestSettings');
            });

            const integration = 'slack_user';
            const state = localStorage.getItem('slackOAuthState');
            localStorage.removeItem('slackOAuthState');
            if (to.query.code && to.query.state === state) {
              const teamSlug = store.getters.getTeamSlug;
              const redirectUri = `${window.location.origin}/${teamSlug}/settings/notifications`;
              store
                .dispatch('createInstallationForIntegration', {
                  integration,
                  payload: {
                    slack_user: {
                      code: to.query.code,
                      redirect_uri: redirectUri,
                    },
                  },
                })
                .then(() => {
                  store
                    .dispatch('getTeamIntegrationInstallations', {
                      integration,
                    })
                    .then(() => {
                      store.dispatch('getTeamIntegrationsSettings', integration).then((data) => {
                        if (data.slack_user) {
                          store.dispatch('setIntegrationSettings', data.slack_user);
                        }
                        router.push(`/${teamSlug}/settings/notifications`);
                        next();
                      });
                    });
                })
                .catch(() => {
                  next();
                });
            } else {
              store
                .dispatch('getTeamIntegrationInstallations', { integration })
                .then(() => {
                  store.dispatch('getTeamIntegrationsSettings', integration).then((data) => {
                    if (data) {
                      store.dispatch('setIntegrationSettings', data.slack_user);
                    }
                    next();
                  });
                })
                .catch(() => {
                  next();
                });
            }
          },
        },
      ],
    },
    {
      path: '/:team_subdomain',
      name: 'project-pages',
      component: () =>
        import(/* webpackChunkName: "projectPages" */ '../components/pages/ProjectPages.vue'),
      redirect: (to) => {
        const { getTeamSlug, getTeamID } = store.getters;

        const key = `${getTeamID}-currentProjectId`;
        const currentProjectId = localStorage.getItem(key);
        if (currentProjectId) {
          return { path: `/${getTeamSlug}/space-${currentProjectId}/boards` };
        }
        return { path: `/${getTeamSlug}/${constants.manageSpacesRouteName}` };
      },
      children: [
        {
          path: '/:team_subdomain/page-:pageId:pageTitle(-.*)?',
          name: 'page',
          meta: {
            scrollPositions: {},
          },
          component: () =>
            import(
              /* webpackChunkName: "projectPages" */ '../components/sections/PageColumnNewEditor.vue'
            ),
          beforeEnter(to, _from, next) {
            store.dispatch('setCurrentBoardId', '');
            store.dispatch('setCurrentTabName', 'pages');
            store.dispatch('setCurrentPageID', to.params.pageId);
            next();
          },
        },
        /* {
          path: '/:team_subdomain/neweditor/page-:pageId:pageTitle(-.*)?',
          name: 'page',
          meta: {
            scrollPositions: {},
          },
          component: () =>
            import(
              // webpackChunkName: "projectPages" '../components/sections/PageColumnNewEditor.vue'
            ),
          beforeEnter(to, _from, next) {
            store.dispatch('setCurrentBoardId', '');
            store.dispatch('setCurrentTabName', 'pages');
            store.dispatch('setCurrentPageID', to.params.pageId);
            next();
          },
        }, */
        {
          path: '/:team_subdomain/board-:boardId(\\d+):boardTitle?/:cardsLayout?',
          name: constants.routeNames.board,
          meta: {
            scrollPositions: {},
          },
          props: true,
          component: () =>
            import(/* webpackChunkName: "projectPages" */ '../components/pages/BoardView.vue'),
          beforeEnter(to, _from, next) {
            boardViewBeforeEnter(store, to, _from, next);
          },
          children: [
            {
              path: '/:team_subdomain/card-:cardId:cardTitle(-.*)?',
              name: constants.routeNames.boardCard,
              beforeEnter(_to, _from, next) {
                cardBeforeEnterHandler(store, router, _to, _from, next);
              },
            },
          ],
        },
        {
          path: '/:team_subdomain/card-:cardId:cardTitle(-.*)?',
          name: 'cardnotfound',
          component: () =>
            import(/* webpackChunkName: "projectPages" */ '../components/pages/CardNotFound.vue'),
        },
        {
          path: `/:team_subdomain/:projectIdParam(${constants.projectSlug}-.*)/newboard`,
          name: 'newboard',
          component: () =>
            import(/* webpackChunkName: "projectPages" */ '../components/pages/NewBoard.vue'),
        },
        {
          path: `/:team_subdomain/:projectIdParam(${constants.projectSlug}-.*)/newpage`,
          name: 'newpage',
          component: () =>
            import(
              /* webpackChunkName: "projectPages" */ '../components/sections/PageColumnNewEditor.vue'
            ),
          beforeEnter(to, from, next) {
            if (store.getters.getFavouritesOpened) {
              store.dispatch('setFavouritesOpened', false);
              store.dispatch('setOpenedFavouriteItem', {});
            }
            if (from.name === null) {
              store.dispatch('setNewPageCreating', true);
              store.dispatch('setCurrentPageID', 'newpage');
            }
            next();
          },
        },
        {
          path: `/:team_subdomain/${constants.projectSlug}-:projectId/sprints`,
          name: constants.routeNames.sprintsRouteName,
          component: () =>
            import(/* webpackChunkName: "projectPages" */ '../components/sprints/Sprints.vue'),
        },
        /* {
          path: `/:team_subdomain/:projectIdParam(${constants.projectSlug}-.*)/neweditor-newpage`,
          name: 'newpage',
          component: () =>
            import(
             // webpackChunkName: "projectPages" '../components/sections/PageColumnNewEditor.vue'
            ),
          beforeEnter(to, from, next) {
            if (store.getters.getFavouritesOpened) {
              store.dispatch('setFavouritesOpened', false);
              store.dispatch('setOpenedFavouriteItem', {});
            }
            if (from.name === null) {
              store.dispatch('setNewPageCreating', true);
              store.dispatch('setCurrentPageID', 'newpage');
            }
            next();
          },
        }, */
        {
          path: `/:team_subdomain/:projectIdParam(${constants.projectSlug}-.*)`,
          name: 'project',
          redirect: (to) => {
            const [projectId] = to.params.projectIdParam.split('/');
            return `/${to.params.team_subdomain}/${projectId}/boards`;
          },
          component: () =>
            import(
              /* webpackChunkName: "projectPages" */ '../components/sections/ProjectContentColumn.vue'
            ),
          children: [
            {
              path: 'boards',
              name: 'boards',
              meta: {
                scrollPositions: {},
              },
              beforeEnter(_to, _from, next) {
                store.dispatch('setCurrentTabName', 'boards');
                next();
              },
            },
          ],
        },
        {
          path: '/:team_subdomain/manage-spaces',
          name: constants.manageSpacesRouteName,
          component: () =>
            import(
              /* webpackChunkName: "projectPages" */ '../components/pages/ProjectManagement.vue'
            ),
          beforeEnter: (_, __, next) => {
            if (store.getters.getItemNotFound) {
              store.dispatch('itemNotFound', false);
            }
            if (store.getters.getCurrentProjectId) {
              store.dispatch('setCurrentProjectId', '');
            }
            next();
          },
        },
        {
          path: '/:team_subdomain/create-space',
          name: constants.createSpaceRouteName,
          component: () =>
            import(
              /* webpackChunkName: "projectPages" */ '../components/pages/ProjectManagement.vue'
            ),
          beforeEnter: (_, __, next) => {
            if (store.getters.getItemNotFound) {
              store.dispatch('itemNotFound', false);
            }
            next();
          },
        },
        {
          path: '/manage-spaces*',
          redirect: (to) => {
            const teamSlug = store.getters.getTeamSlug;
            return { path: `/${teamSlug}${to.path}`, query: to.query };
          },
        },
        {
          path: '/create-space',
          redirect: (to) => {
            const teamSlug = store.getters.getTeamSlug;
            return { path: `/${teamSlug}${to.path}`, query: to.query };
          },
        },
        // START - legcy project redirect
        {
          path: '/:team_subdomain/:projectIdParam(project-.*)/newboard',
          redirect: (to) => {
            const [, projectId] = to.params.projectIdParam.split('-');
            return `/${to.params.team_subdomain}/${constants.projectSlug}-${projectId}/newboard`;
          },
        },
        {
          path: '/:team_subdomain/:projectIdParam(project-.*)/newpage',
          redirect: (to) => {
            const [, projectId] = to.params.projectIdParam.split('-');
            return `/${to.params.team_subdomain}/${constants.projectSlug}-${projectId}/newpage`;
          },
        },
        {
          path: '/:team_subdomain/:projectIdParam(project-.*)/boards',
          redirect: (to) => {
            const [, projectId] = to.params.projectIdParam.split('-');
            return `/${to.params.team_subdomain}/${constants.projectSlug}-${projectId}/boards`;
          },
        },
        {
          path: '/:team_subdomain/:projectIdParam(project-.*)/sprints',
          redirect: (to) => {
            const [, projectId] = to.params.projectIdParam.split('-');
            return `/${to.params.team_subdomain}/${constants.projectSlug}-${projectId}/sprints`;
          },
        },
        // END - legcy project redirect

        // {
        //  path: '/:team_subdomain/archived',
        //  name: 'archived',
        //  component:
        //    () => import(/* webpackChunkName: "projectPages" */'./components/pages/Archived.vue'),
        // },
        {
          path: `/:team_subdomain/${constants.epicsBoardRoute.path}/:cardsLayout?`,
          name: constants.epicsBoardRoute.name,
          meta: {
            scrollPositions: {},
          },
          props: true,
          component: () =>
            import(/* webpackChunkName: "projectPages" */ '../components/pages/BoardView.vue'),
          beforeEnter(_to, _from, next) {
            if (store.getters.getCurrentRole === TeamMemberRole.Guest) {
              store.dispatch('itemNotFound', true);
              return;
            }

            if (_to.name === constants.epicsBoardRoute.name) {
              _to.params.boardId = constants.epicsBoardId;
              store.dispatch('setCurrentBoardId', constants.epicsBoardId);
              if (store.getters.getCurrentProjectId) {
                store.dispatch('setCurrentProjectId', '');
              }
            }

            next();
          },
          children: [
            {
              path: `/:team_subdomain/${constants.epicRoute.path}-:cardId-:cardTitle?/:selectedTabId?`,
              name: constants.epicRoute.name,
              beforeEnter(_to, _from, next) {
                if (
                  !_to.params.selectedTabId ||
                  Object.values(EpicTabId).indexOf(_to.params.selectedTabId as EpicTabId) === -1
                ) {
                  _to.params.selectedTabId = store.getters.getEpicSelectedTabId(_to.params.cardId);
                }

                if (_to.params.selectedTabId) {
                  store.dispatch('setEpicSelectedTabId', {
                    cardId: _to.params.cardId,
                    tabId: _to.params.selectedTabId,
                  });
                }
                cardBeforeEnterHandler(store, router, _to, _from, next);
              },
            },
          ],
        },
        {
          // Sprint id format in url is projectId + 's' + sprintId because fetching sprint by sprintId is not possible without projectId
          path: '/:team_subdomain/sprint-:sprintId(\\d+s\\d+):sprintTitle?/:cardsLayout?',
          name: constants.routeNames.sprint,
          meta: {
            scrollPositions: {},
          },
          props: true,
          component: () =>
            import(/* webpackChunkName: "projectPages" */ '../components/sprints/SprintView.vue'),
          beforeEnter(to, _from, next) {
            if (to.params.sprintId) {
              store.dispatch('setCurrentBoardId', to.params.sprintId);
              store.dispatch('setCurrentTabName', 'boards');
            }
            next();
          },
          children: [
            {
              path: '/:team_subdomain/sprintcard-:cardId:cardTitle(-.*)?',
              name: constants.routeNames.sprintCard,
              component: () =>
                import(
                  /* webpackChunkName: "projectPages" */ '../components/widgets/BoardListCardDetails.vue'
                ),
              beforeEnter(_to, _from, next) {
                cardBeforeEnterHandler(store, router, _to, _from, next);
              },
            },
          ],
        },
        {
          path: `/${constants.epicsBoardRoute.path}`,
          redirect: (to) => {
            const teamSlug = store.getters.getTeamSlug;
            return { path: `/${teamSlug}${to.path}` };
          },
        },
        {
          path: '/:team_subdomain/views',
          name: 'views',
          component: () => import(/* webpackChunkName: "views" */ '../components/pages/Views.vue'),
        },
        {
          path: '/views',
          redirect: (to) => {
            const teamSlug = store.getters.getTeamSlug;
            return { path: `/${teamSlug}${to.path}` };
          },
        },
        {
          path: '/:team_subdomain/newview',
          name: 'newview',
          component: () =>
            import(/* webpackChunkName: "views" */ '../components/pages/NewView.vue'),
        },
        {
          path: '/:team_subdomain/view-:viewId:viewTitle(-.*)?',
          name: 'view',
          component: () =>
            import(/* webpackChunkName: "views" */ '../components/pages/SingleView.vue'),
        },
        {
          path: '/:team_subdomain/inbox',
          name: 'inbox',
          component: () => import(/* webpackChunkName: "inbox" */ '../components/pages/Inbox.vue'),
        },
        {
          path: '/:team_subdomain/my-work/:selectedTabId?',
          name: 'my-work',
          component: () =>
            import(/* webpackChunkName: "projectPages" */ '../components/pages/MyWork.vue'),
          beforeEnter(_to, from, next) {
            store.dispatch('setMyWorkUserId', store.getters.getUserId);
            next();
          },
        },
        {
          path: '/my-work',
          redirect: (to) => {
            const teamSlug = store.getters.getTeamSlug;
            return { path: `/${teamSlug}${to.path}` };
          },
        },
        {
          path: '/:team_subdomain/member-:userId/:selectedTabId?',
          name: 'member',
          component: () =>
            import(/* webpackChunkName: "projectPages" */ '../components/pages/MyWork.vue'),
          beforeEnter(to, from, next) {
            if (!from.name) {
              store.dispatch('fetchTeamMembers').catch(() => {});
            }
            store.dispatch('setMyWorkUserId', to.params.userId);
            next();
          },
        },
      ],
    },
    {
      path: '/oauth2/authorize/',
      name: 'authorize',
      component: () =>
        import(
          /* webpackChunkName: "loginOnboarding" */ '../components/pages/IntegrationWorkspaceList.vue'
        ),
    },
  ],
});

router.beforeEach((to: any, from: any, next: Function) => {
  beforeEachGuard(to, from, next);
});

router.afterEach((to: any) => {
  afterEachGuard(to);
});

export default router;
