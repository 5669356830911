const isElectronApp = () => {
  return navigator.userAgent.indexOf('Electron') >= 0;
};

export default {
  computed: {
    isElectronApp() {
      return isElectronApp();
    },
  },
};

export { isElectronApp };
