import { GetterTree } from 'vuex';
import { AttachmentPreviewState } from './attachmentPreviewState';

export default {
  getPreviewAttachmentIndex: (state) => state.previewAttachmentIndex,
  getPreviewAttachments: (state) => state.previewAttachments,
  getAttachmentPreviewFromInput: (state) => state.attachmentPreviewFromInput,
  getPreviewAttachmentId: (state) => state.previewAttachmentId,
  getPreviewAttachmentResourceId: (state) => state.previewAttachmentResourceId,
} as GetterTree<AttachmentPreviewState, any>;
