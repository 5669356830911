/* eslint-disable no-param-reassign */
const objectSet = (object, path, value) => {
  const decomposedPath = path;
  const base = decomposedPath[0];

  if (base === undefined) {
    return object;
  }

  if (Array.isArray(object) && !object.length) {
    object = [];
  } else if (!Array.isArray(object) && !Object.prototype.hasOwnProperty.call(object, base)) {
    object[base] = {};
  }
  // Determine if there is still layers to traverse
  value =
    decomposedPath.length <= 1 ? value : objectSet(object[base], decomposedPath.slice(1), value);

  if (Array.isArray(object)) {
    return [...object, value];
  }

  return {
    ...object,
    [base]: value,
  };
};

export default objectSet;
