export default {
  computed: {
    iconsContext() {
      return require.context('../assets/icons/', false);
    },
  },
  methods: {
    iconPath(name, extension = 'svg') {
      if (!name) return '';
      try {
        return this.iconsContext(`./${name}.${extension}`);
      } catch (error) {
        return '';
      }
    },

    getCustomIconStyle(path, styleOptions = {}) {
      return {
        // require set with css: background-color, width and height
        maskImage: `url(${path})`,
        webkitMaskImage: `url(${path})`,
        maskSize: 'contain', // change with css if needed, use !important to apply
        webkitMaskSize: 'contain',
        maskRepeat: 'no-repeat',
        webkitMaskRepeat: 'no-repeat',
        ...styleOptions,
      };
    },
  },
};
