import { MutationTree } from 'vuex';
import { AttachmentPreviewState } from './attachmentPreviewState';
import attachmentPreviewable from '@/utilities/attachmentPreviewable';

export default {
  setPreviewAttachments(state, attachments) {
    state.previewAttachments = attachments.filter((attachment: any) =>
      attachmentPreviewable(attachment)
    );
  },

  setPreviewAttachmentId(state, { attachmentId, resourceId }) {
    const attachments = [...state.previewAttachments];

    const attachmentIndex = attachments.findIndex((a) => a.id === attachmentId);
    if (attachmentIndex !== -1) {
      state.previewAttachmentIndex = attachmentIndex;
    }
    state.previewAttachmentId = attachmentId;
    state.previewAttachmentResourceId = resourceId;
  },

  clearCardPreviewData(state) {
    state.previewAttachmentId = '';
    state.previewAttachmentIndex = -1;
    state.previewAttachments = [];
  },

  setNextAttachment(state, id) {
    const { length } = state.previewAttachments;
    const attachments = state.previewAttachments;

    if (state.previewAttachmentIndex === length - 1 || state.previewAttachmentIndex === length) {
      state.previewAttachmentIndex = 0;
      state.previewAttachmentId = attachments[0].id;
      return;
    }

    state.previewAttachmentIndex += 1;
    state.previewAttachmentId = attachments[state.previewAttachmentIndex].id;
  },

  setPreviousAttachment(state, id) {
    const { length } = state.previewAttachments;
    const attachments = state.previewAttachments;

    if (state.previewAttachmentIndex === 0) {
      state.previewAttachmentIndex = length - 1;
      state.previewAttachmentId = attachments[state.previewAttachmentIndex].id;
      return;
    }

    state.previewAttachmentIndex -= 1;
    state.previewAttachmentId = attachments[state.previewAttachmentIndex].id;
  },

  deleteAttachmentFromPreviewAttachments(state, fileId) {
    const index = state.previewAttachments.findIndex((att: any) => att.id === fileId);

    if (index > -1) {
      state.previewAttachments.splice(index, 1);
    }
  },

  setAttachmentPreviewFromInput(state, value) {
    state.attachmentPreviewFromInput = value;
  },
} as MutationTree<AttachmentPreviewState>;
