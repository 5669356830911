export default {
  computed: {
    draggedEntity() {
      if (!this.$store) return {};
      return this.$store.getters.draggedEntity;
    },
    draggingIsOn() {
      return Object.keys(this.draggedEntity).length > 0;
    },
  },
};
