export interface AttachmentPreviewState {
  previewAttachments: Array<any>;
  previewAttachmentId: string;
  previewAttachmentResourceId: string;
  previewAttachmentIndex: number;
  attachmentPreviewFromInput: boolean;
}

export default {
  previewAttachments: [],
  previewAttachmentId: '',
  previewAttachmentResourceId: '',
  previewAttachmentIndex: -1,
  attachmentPreviewFromInput: false,
} as AttachmentPreviewState;
