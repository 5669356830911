export default {
  computed: {
    isAnyCardDisplayed() {
      return this.$store.getters.getIsAnyCardDisplayed;
    },
    isCardOpen() {
      return this.isAnyCardDisplayed;
    },
  },
};
