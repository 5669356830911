import constants from './constants';

const cdnBase = 'https://app.superthread.com/cdn-cgi/image';

const generateSrcUrl = (src, width, height, pageCover = false) => {
  if (
    src.includes(constants.UNSPLASH_BASE_URL) ||
    src.includes('superthread.com/public') ||
    src.includes('https://lh3.googleusercontent.com')
  ) {
    const encoded = encodeURI(src);
    return `${cdnBase}/q=90,h=${height},w=${width}${pageCover ? ',fit=cover' : ''}/${encoded}`;
  }
  return src;
};

export default generateSrcUrl;
