import attachGScript from '@/utilities/attachGScript';

declare global {
  interface Window {
    ga: any;
  }
}

const ga = (...args: any) => {
  if (!window.ga) {
    window.ga = {};
  }
  if (!window.ga.q) window.ga.q = [];
  window.ga.q.push(args);
};

const gaInit = (clientTrackingId: string) => {
  if (!clientTrackingId) return Promise.reject(new Error('No client tracking Id'));
  return attachGScript()
    .then((/* event */) => {
      window.ga = ga;
      window.ga.l = +new Date();
      window.ga('create', clientTrackingId);
      window.ga('send', 'pageview');
    })
    .catch((/* error */) => {
      console.warn('Google Analytics script not loaded.');
    });
};

const gaMixin = {
  methods: {
    sendGAEvent(eventCategory = '', eventAction = '', eventLabel = '') {
      ga('send', 'event', eventCategory, eventAction, eventLabel);
    },
  },
};

export { gaInit, gaMixin, ga };
