import { computed } from 'vue';

export default function useCustomIcon() {
  const iconsContext = computed(() => {
    return require.context('@/assets/icons', false);
  });

  function iconPath(name: string, extension = 'svg') {
    if (!name) return '';
    try {
      return iconsContext.value(`./${name}.${extension}`);
    } catch (error) {
      return '';
    }
  }

  function getCustomIconStyle(path: string) {
    return {
      // require set with css: background-color, width and height
      maskImage: `url(${path})`,
      webkitMaskImage: `url(${path})`,
      maskSize: 'contain', // change with css if needed, use !important to apply
      webkitMaskSize: 'contain',
      maskRepeat: 'no-repeat',
      webkitMaskRepeat: 'no-repeat',
    };
  }

  return {
    iconPath,
    getCustomIconStyle,
  };
}
