import specialCharactersPattern from './specialCharactersPattern';

const getDashedTitle = (title = '') =>
  title
    .replaceAll(/^\s+|\s+$/g, '')
    .replaceAll(/-{2,}|\s/g, '-')
    .replaceAll(/-{2,}|\s/g, '-')
    .replaceAll(' ', '-')
    .replace(specialCharactersPattern, '')
    .toLowerCase();

export default getDashedTitle;
