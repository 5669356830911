import store from '@/store';
import constants from '@/utilities/constants';
import { ga } from '@/mixins/gAnalytics';
import { Page, Project, Team } from '@/types';
import isMobile from '../../utilities/isMobile';
import { isElectronApp } from '@/mixins/electronHelpersMixin';

let popstateDetected = false;

window.addEventListener('popstate', () => {
  popstateDetected = true;
});

const isLoggingIn = () => {
  const localLogin = localStorage.getItem('login');
  return localLogin === 'authProvider';
};

const removeLocalStorageItem = (item: string) => {
  localStorage.removeItem(item);
};

const setUser = (user: any) => {
  store.dispatch('setLoggedIn');
  store.dispatch('setUser', user);
  store.dispatch('setOnboardingUserID', user.id);
};

const setTeam = (team: any) => {
  store.dispatch('setCurrentTeamId', team.id);
  store.dispatch('setTeamSubDomain', team.sub_domain);
  const key = `${team.id}-currentProjectId`;
  const currentProjectId = localStorage.getItem(key);
  if (currentProjectId) {
    store.dispatch('setCurrentProjectId', currentProjectId);
  }
};

const getPendingInvities = (user: any, next: Function) => {
  if (window.location.pathname === '/oauth2/authorize') {
    return;
  }
  store
    .dispatch('getPendingInvities')
    .then((data) => {
      if (data.pending_invites.length === 0 && user.teams.length === 1) {
        setTeam(user.teams[0]);
        const defaultTabName = store.getters.getDefaultTabName;
        return next(defaultTabName);
      }
      if (data.pending_invites.length || user.teams.length) {
        store.dispatch('setOnboardingCurrentForm', 'select_workspace');
        return next({ name: constants.routeNames.workspaces });
      }
      if (isLoggingIn()) {
        store.dispatch('setOnboardingErrorMessage', '');
        store.dispatch('setOnboardingCurrentForm', 'team_name');
        return next({ name: constants.routeNames.newWorkspace });
      }
      // no teams and no pending invites
      if (data.pending_invites.length === 0 && user.teams.length === 0) {
        store.dispatch('setOnboardingCurrentForm', 'team_name');
        return next({ name: constants.routeNames.newWorkspace });
      }
      removeLocalStorageItem('login');
    })
    .catch(() => {
      if (user.teams?.length) {
        store.dispatch('setOnboardingCurrentForm', 'select_workspace');
        return next({ name: constants.routeNames.workspaces });
      }
      store.dispatch('setOnboardingCurrentForm', 'team_name');
      return next({ name: constants.routeNames.newWorkspace });
    });
};

const handleRedirectLogin = (user: any, to: any, next: Function) => {
  const teamSubDomain = store.getters.getTeamSubDomain;
  const localCurrentTeamId = localStorage.getItem('currentTeamId');
  const teamIndex = user.teams.findIndex((t: Team) => t.id === localCurrentTeamId);
  store.dispatch('setRedirectRouteAfterLogin', {});
  if (teamIndex !== -1) {
    setTeam(user.teams[teamIndex]);
    removeLocalStorageItem('login');
    if (to.name === 'home') {
      const defaultTabName =
        localStorage.getItem('lastVisitedRoute') || store.getters.getDefaultTabName;
      return next(defaultTabName);
    }
  }
  if (
    to.params.team_subdomain &&
    (!localCurrentTeamId || teamSubDomain !== to.params.team_subdomain)
  ) {
    const team = user.teams.find((t: Team) => t.sub_domain === to.params.team_subdomain);
    if (team && Object.keys(team).length) {
      setTeam(team);
    } else {
      getPendingInvities(user, next);
    }
  } else if (teamIndex === -1) {
    getPendingInvities(user, next);
  }
};

const checkGuestAttempt = (to: any, from: any, next: Function) => {
  // when user is guest and trying to access create-space or settings/create-space
  if (
    store.getters.getTeam?.role === 'guest' &&
    [constants.createSpaceRouteName, constants.settingsCreateSpaceRouteName].includes(to.name)
  ) {
    next({
      name: constants.manageSpacesRouteName,
      path: `${store.getters.getTeamSubDomain}/${constants.manageSpacesRouteName}`,
      params: { team_subdomain: store.getters.getTeamSubDomain },
    });
  }
};

const handleRedirect = (user: any, to: any) => {
  const teamSubDomain = store.getters.getTeamSubDomain;
  const currentTeamId = localStorage.getItem('currentTeamId') || -1;
  let teamIndex = -1;
  if (currentTeamId !== -1) {
    teamIndex = user.teams.findIndex((t: Team) => t.id === currentTeamId);
  }
  if (teamIndex > -1) {
    setTeam(user.teams[teamIndex]);
  } else if (user.teams.length) {
    const defaultTeam = user.teams[0];
    setTeam(defaultTeam);
  }
  if (to.params.team_subdomain && (!currentTeamId || teamSubDomain !== to.params.team_subdomain)) {
    const team = user.teams.find((t: any) => t.sub_domain === to.params.team_subdomain);
    setTeam(team);
  }
};

const tryFetchingUser = (to: any, from: any, next: Function) =>
  store
    .dispatch('loadUser')
    .then(({ user }) => {
      setUser(user);
      handleRedirectLogin(user, to, next);
      checkGuestAttempt(to, from, next);

      store.dispatch('setOnboardingErrorMessage', '');
      store.dispatch('setLoginActionStatus', true);

      if (!from.name) {
        /* User reloading or coming fresh */
        if (to.name === 'page') {
          store.dispatch('setCurrentTabName', 'pages');
        }

        if (to.name === 'board') {
          store.dispatch('setCurrentTabName', 'boards');
        }
      }
      next();
    })
    .catch((/* error */) => {
      store.dispatch('setPreviousRoute', to);
      store.dispatch('setRedirectRouteAfterLogin', to);
      store.dispatch('setNotLoggedIn');
      if (to.path !== '/login') {
        return next('/login');
      }
    });

const handleLogin = (to: any, from: any, next: Function) => {
  if (!store.getters.getLoggedIn || !Object.keys(store.getters.getUser).length) {
    tryFetchingUser(to, from, next);
  } else if (
    from.name === null &&
    to.name !== 'login' &&
    to.name !== 'create' &&
    to.name !== 'join'
  ) {
    store
      .dispatch('loadUser')
      .then(({ user }) => {
        setUser(user);
        handleRedirect(user, to);
        checkGuestAttempt(to, from, next);
        if (to.name === 'home') {
          if (store.getters.getTeamID && !isLoggingIn()) {
            const teamSlug = store.getters.getTeamSlug;
            const currentProjectId = `${constants.projectSlug}-${store.getters.getCurrentProjectId}`;
            const currentTabName = store.getters.getCurrentTabName;
            removeLocalStorageItem('login');
            return next(`/${teamSlug}/${currentProjectId}/${currentTabName}`);
          }
        }
        next();
      })
      .catch(() => {
        // intentional empty catch block
      });
  } else {
    store.dispatch('setPreviousRoute', from);
    checkGuestAttempt(to, from, next);
    next();
  }
};

const loadProjects = async () => {
  await store.dispatch('loadTeamProjects').then((data) => {
    data.forEach((project: Project) => {
      const projectId = project.id;
      const pagesIds: string[] = [];
      const pagesObject: { [key: string]: Page } = {};
      const pagesTree: { [key: string]: { [key: string]: any } } = {
        root: { id: 'root', children: [] },
      };
      const archivePages: { [key: string]: Page } = {};
      const archivedPagesTree: { [key: string]: Page } = {};

      project.pages.forEach((page: Page) => {
        pagesIds.push(page.id);
        pagesObject[page.id] = page;
      });
      [...(project.page_order || [])].forEach((p) => {
        pagesTree[p.id] = p;
      });
      [...(project.archive_pages || [])].forEach((p) => {
        archivePages[p.id] = p;
      });
      [...(project.archive_page_order || [])].forEach((p) => {
        archivedPagesTree[p.id] = p;
      });

      store.dispatch('setProjectPages', { projectId, pagesIds });
      store.dispatch('setPages', pagesObject);
      store.dispatch('setProjectPagesTree', { projectId, pagesTree });
      store.dispatch('setFlatBoards', project.boards);
      store.dispatch('setProjectBoards', {
        projectId,
        projectBoards: project.board_order,
      });
      store.dispatch('setProjectArchivePagesTree', { projectId, archivedPagesTree });
      store.dispatch('setProjectArchivePages', archivePages);
    });
  });
};

export const beforeEachGuard = async (to: any, from: any, next: Function) => {
  if (
    store.getters.getIsAnyCardDisplayedInLayout(constants.fullScreen) &&
    to.name !== constants.routeNames.boardCard &&
    to.name !== constants.routeNames.sprintCard &&
    to.name !== constants.epicRoute.name
  ) {
    store.dispatch('setDisplayedCards');
  }
  if (
    !store.getters.getPreviousNextButtonClicked &&
    to.path !== '/' &&
    isElectronApp() &&
    !(
      from.path === '/' &&
      (to.name === constants.routeNames.boardCard ||
        to.name === constants.epicRoute.name ||
        to.name === constants.routeNames.sprintCard)
    )
  ) {
    store.dispatch('addToElectronBrowseHistory', {
      path: to.path,
      replaceAfterIndex:
        store.getters.getElectronBrowseHistoryIndex !==
        store.getters.getElectronBrowseHistory.length - 1
          ? store.getters.getElectronBrowseHistoryIndex
          : null,
    });
    store.dispatch(
      'setElectronBrowseHistoryIndex',
      store.getters.getElectronBrowseHistoryIndex + 1
    );
  }
  if (
    isMobile() &&
    to.name !== constants.routeNames.boardCard &&
    to.name !== constants.routeNames.sprintCard &&
    to.name !== constants.epicRoute.name
  ) {
    store.dispatch('setDisplayedCards');
  }
  store.dispatch('setCurrentRoute', to);
  if (
    (from.name === 'page' ||
      from.name === 'board' ||
      from.name === constants.epicsBoardRoute.name) &&
    (to.name === 'boards' ||
      to.name === 'pages' ||
      to.name === 'view' ||
      to.name === 'views' ||
      to.name === 'board-card' ||
      to.name === 'sprint-card' ||
      to.name === constants.epicRoute.name ||
      to.name === 'board' ||
      to.name === 'page' ||
      to.name === 'inbox')
  ) {
    if (store.getters.getFavouritesOpened) {
      store.dispatch('setFavouritesOpened', false);
      store.dispatch('setOpenedFavouriteItem', {});
    }
  }
  if (to.name !== from.name && (to.name === 'inbox' || to.name === 'views')) {
    store.dispatch('setCurrentBoardId', '');
    store.dispatch('setCurrentPageID', '');
    store.dispatch('setPage', { content: '' });
  }

  if (to.meta?.requiresAuth === false) {
    store.dispatch('setPreviousRoute', from);
    next();
  } else {
    handleLogin(to, from, next);
  }

  if (
    ![
      'board',
      'board-card',
      'sprint-card',
      constants.epicRoute.name,
      constants.epicsBoardRoute.name,
    ].includes(`${to.name}`)
  ) {
    // destroy board view
    store.dispatch('setBoardViewNoDestroy', false);
  }

  if (store.getters.socketDeletedProjects.length) {
    store.dispatch('clearSocketDeletedProjects');
  }

  if (!popstateDetected) {
    switch (to.name) {
      case constants.epicsBoardRoute.name:
      case 'board':
        to.meta.scrollPositions[to.params.boardId] = 0;
        break;
      case 'page':
        to.meta.scrollPositions[to.params.pageId] = 0;
        break;
      case 'boards': {
        const projectId = to.params.projectIdParam.split('-')[1];
        to.meta.scrollPositions[projectId] = 0;
        break;
      }
      default:
        break;
    }
  } else if (to.name === 'boards' || to.name === 'sprints') {
    const toProjectId = to.params.projectIdParam?.split('-')[1] || to.params.projectId;

    if (toProjectId !== store.getters.getCurrentProjectId) {
      const project = store.getters.getMyProjectById(toProjectId);
      if (!project) {
        await loadProjects();
      }
      store.dispatch('setCurrentProjectId', toProjectId);
    }
  }
  to.meta.popstate = popstateDetected;
  popstateDetected = false;
};

export const afterEachGuard = (to: any) => {
  ga('set', 'page', to.path);
  ga('send', 'pageview');
  if (
    !['/login', '/desktop-redirect', '/oauth2'].includes(to.path) &&
    !['authorize', 'login', 'oauth2', 'desktop-redirect'].includes(to.name)
  ) {
    localStorage.setItem('lastVisitedRoute', to.path);
  }
};

export default {
  beforeEachGuard,
  afterEachGuard,
};
