import cons from '@/utilities/constants';
import isMob from '@/utilities/isMobile';

/* eslint-disable no-param-reassign */
function install(Vue, options) {
  // make reactive objects
  const breakpoints = Vue.observable({});

  let isMobile = Vue.observable(false);
  isMobile = isMob();

  const updateBreakpoints = (e, key) => {
    Vue.set(breakpoints, key, e.matches);
  };

  const addMediaMatchers = () => {
    const matchers = {};
    Object.keys(cons.screens).forEach((key) => {
      matchers[key] = window.matchMedia(`(min-width: ${cons.screens[key]})`);
      matchers[key].addListener((e) => updateBreakpoints(e, key));
      updateBreakpoints(matchers[key], key);
    });
  };

  addMediaMatchers();

  // inject global component computed
  Vue.mixin({
    computed: {
      bps() {
        return breakpoints;
      },
      isAndroid() {
        return navigator.userAgent.match(/android/i);
      },
      isMobile() {
        return isMobile;
      },
      isMobileScreen() {
        return !this.bps.sm;
      },
      isTabletScreen() {
        return this.bps.sm && this.bps.md && !this.bps.lg;
      },
      isDesktopScreen() {
        return this.bps.lg;
      },
    },
  });
}

const BreakpointsPlugin = { install };

export default BreakpointsPlugin;
