//./plugins/posthog.js
import posthog from 'posthog-js';
import constants from '@/utilities/constants';

export default {
  install(Vue, options) {
    Vue.prototype.$posthog = posthog.init(constants.postHogApiKey, {
      api_host: constants.postHogApiHost,
      person_profiles: 'identified_only',
      session_recording: {
        maskAllInputs: true,
        maskTextSelector: `
          .activity-user-name,
          .actor-name,
          .beautified-link--label *,
          .board-item-card-title,
          .board-title-in-list,
          .breadcrumb-item-text,
          .card-child-title,
          .card-project-label,
          .card-project-title,
          .card-title,
          .child-page-section-item *,
          .filter-dropdown-label span,
          .item-label,
          .list-title,
          .notification-text,
          .page-list-item-text,
          .page-list-title,
          .parent-card-title,
          .posted-by-user,
          .project-text,
          .resource-wrap,
          .resource-title,
          .st-editor-content *,
          .tag-name,
          .title *,
          .title-text,
          .toc-item
        `,
      },
    });
  },
};
