import { Project } from '@/types';
import EventBus from './eventBus';

const localUpdatePageProp = (pageId: string, value: any, prop: string, storeDispatch: any) => {
  storeDispatch('localUpdatePageProp', {
    pageId,
    propertyName: prop,
    value,
  });
  if (prop === 'icon') {
    EventBus.$emit(`${pageId}-updatePageIcon`, value);
  }
};

const updateBoardPropertyInBoards = (
  boardId: string,
  value: any,
  prop: string,
  storeDispatch: any
) => {
  storeDispatch('updateBoardPropertyInBoards', {
    boardId,
    propertyName: prop,
    value,
  });
};

const updateBoard = (
  newProject: Project,
  type: string,
  path: [string, any, string, any],
  to: any,
  from: any,
  storeDispatch: any,
  storeGetters: any
) => {
  const [_, index, prop] = path;
  const boardId = newProject.board_order?.[index];

  if (prop === 'title' && boardId) {
    updateBoardPropertyInBoards(boardId, to, 'title', storeDispatch);
  }

  if (type === 'create') {
    updateBoardPropertyInBoards(to.id, false, 'archived', storeDispatch);
    const board = storeGetters.getFlatBoards[to.id];
    if (board?.is_bookmarked) {
      storeDispatch('addBoardToBookmarked', board);
    }
  }
};

const updatePage = (
  newProject: Project,
  type: string,
  path: [string, any, string, any],
  to: any,
  from: any,
  storeDispatch: any
) => {
  const project = newProject;
  const [_, index, prop, prop2] = path;
  const page = newProject.pages?.[index] ?? {};
  const pageId = page?.id;

  if (type === 'create') {
    const title = to.title || '';
    const icon = to.icon;
    localUpdatePageProp(to.id, title, 'title', storeDispatch);
    if (icon) {
      localUpdatePageProp(to.id, icon, 'icon', storeDispatch);
    }

    if (project.pages?.findIndex((page: any) => page.id === to.id) === -1) {
      project.pages.splice(index, 0, to);
    }

    storeDispatch('addPageToFlatPages', { ...to, projectId: project.id });
    storeDispatch('addPageToProjectPages', {
      pageId: page.id,
      projectId: project.id,
    });
  }

  if (type === 'update' && pageId) {
    if (prop === 'title') {
      localUpdatePageProp(pageId, to, 'title', storeDispatch);
      page.title = to;
    }
    if (prop === 'icon') {
      if (from === null) {
        page.icon = to;
      } else if (prop2 === 'src' || prop2 === 'color' || prop2 === 'type') {
        page.icon[prop2] = to;
      }
      if (to === null) {
        page.icon = {
          src: '',
          blurhash: '',
          type: '',
        };
      }
      localUpdatePageProp(pageId, page.icon, 'icon', storeDispatch);
    }
  }

  if (type === 'delete') {
    const pageId = from.id;
    const copiedPages = [...(newProject.pages ?? [])];
    const pageIndex = copiedPages.findIndex((page: any) => page.id === pageId);
    if (pageIndex === -1) return project;
    copiedPages.splice(pageIndex, 1);
    project.pages = copiedPages;
  }

  return project;
};

const updateArchivedPages = (
  newProject: Project,
  type: string,
  to: any,
  from: any,
  storeDispatch: any,
  storeGetters: any
) => {
  if (type === 'delete') {
    const archived = false;
    localUpdatePageProp(from.id, archived, 'archived', storeDispatch);
  }

  if (type === 'create') {
    const parentPageId = storeGetters.getProjectPagesTree(newProject.id)[to.id]?.parent;
    const page = storeGetters.getPages[parentPageId];
    if (page?.archived) {
      localUpdatePageProp(to.id, page.archived, 'archived', storeDispatch);
    }
  }
};

const updateBoardPageTitle = (
  project: any,
  type: string,
  path: [string, any, string, any],
  to: any,
  from: any,
  storeDispatch: any,
  storeGetters: any
) => {
  let newProject = project;
  const [basePath] = path;

  if (basePath === 'boards') {
    updateBoard(newProject, type, path, to, from, storeDispatch, storeGetters);
  }

  if (basePath === 'pages') {
    newProject = updatePage(newProject, type, path, to, from, storeDispatch);
  }

  if (basePath === 'archive_pages') {
    updateArchivedPages(newProject, type, to, from, storeDispatch, storeGetters);
  }

  if (basePath === 'page_order' && type === 'update') {
    const newPageTree: any = {};
    [...(to || [])].forEach((p) => {
      newPageTree[p.id] = p;
    });

    storeDispatch('setProjectPageOrderTree', {
      projectId: newProject.id,
      pagesTree: newPageTree,
    });

    /* storeDispatch('setProjectPagesTree', { projectId: newProject.id, to }); */
    newProject.page_order = to;
  }

  if (basePath === 'archive_page_order') {
    const newArchivePageTree: any = {};
    [...(to || [])].forEach((p) => {
      newArchivePageTree[p.id] = p;
    });

    storeDispatch('setProjectArchivedTree', {
      projectId: newProject.id,
      archiveTree: newArchivePageTree,
    });
  }

  return newProject;
};

export default updateBoardPageTitle;
