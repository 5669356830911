import { ActionTree } from 'vuex';
import { AttachmentPreviewState } from './attachmentPreviewState';

export default {
  setPreviewAttachmentId({ commit }, { attachmentId, resourceId }) {
    commit('setPreviewAttachmentId', { attachmentId, resourceId });
  },

  setPreviewAttachments({ commit }, attachements) {
    commit('setPreviewAttachments', attachements);
  },

  clearCardPreviewData({ commit }) {
    commit('clearCardPreviewData');
  },

  setNextAttachment({ commit }, id) {
    commit('setNextAttachment', id);
  },

  setPreviousAttachment({ commit }, id) {
    commit('setPreviousAttachment', id);
  },

  deleteAttachmentFromPreviewAttachments({ commit }, fileId) {
    commit('deleteAttachmentFromPreviewAttachments', fileId);
  },

  setAttachmentPreviewFromInput({ commit }, value) {
    commit('setAttachmentPreviewFromInput', value);
  },
} as ActionTree<AttachmentPreviewState, any>;
