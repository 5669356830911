export enum OnboardingActionType {
  AddWorkspaceLogo = 'add_workspace_logo',
  CustomiseYourProfile = 'customise_your_profile',
  ImportTasks = 'import_tasks',
  CreateCard = 'create_card',
  AddChildCard = 'add_child_card',
  CreatePage = 'create_page',
  MentionAnything = 'mention_anything',
  ViewKeyboardShortcuts = 'view_keyboard_shortcuts',
  ConnectSlack = 'connect_slack',
}

export enum OnboardingActionStatus {
  NotCompleted = 'not_completed',
  Completed = 'completed',
  Dismissed = 'dismissed',
}

export interface OnboardingAction {
  type: OnboardingActionType;
  status: OnboardingActionStatus;
  time_updated?: number;
  animate_completion?: boolean;
}
