<template>
  <div
    ref="stImage"
    class="st-image-wrap"
    :style="divStyle"
    @mouseleave="handleMouseLeave"
  >
    <div
      ref="placeholder"
      class="stImage-loading-placeholder"
      :style="placeholderStyle"
    >
      <BlurHashImage
        v-if="showBlurHash"
        ref="blurhashImage"
        class="blurhash-image"
        :hash="blurHash"
        :h="blurHeight"
        :w="blurWidth"
      />
      <img
        v-show="imageLoaded"
        ref="imgNode"
        :alt="alt"
        loading="lazy"
        :width="respWidth"
        :height="respHeight"
        :style="imgStyle"
      />
    </div>
  </div>
</template>
<script>
import { downloadFile } from '@/utilities/downloads';
import BlurHashImage from '@/components/widgets/BlurHashImage';
import generateSrcUrl from '@/utilities/generateSrcUrl';
import customIconMixin from '@/mixins/customIconsMixin';
import displayFullScreenImage from '@/mixins/displayFullScreenImage';
import EventBus from '@/utilities/eventBus';

const optionsWrapStype = {
  position: 'absolute',
  top: 0,
  right: 0,
  height: 'auto',
  padding: '8px',
  zIndex: 2,
  display: 'flex',
};
// width and height of buttons
const optionButtonDim = 24;
// distance between button and dropdown
const dropdownMargin = 4;
const optionButtonStyle = {
  width: `${optionButtonDim}px`,
  height: `${optionButtonDim}px`,
  backgroundColor: '#101828',
  borderRadius: '4px',
  margin: '0 2px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const iconStyle = {
  cursor: 'pointer',
  backgroundColor: '#ffffff',
  width: '20px',
  height: '20px',
  display: 'block',
};
const dropdownWidth = 240;
export default {
  components: {
    BlurHashImage,
  },
  mixins: [customIconMixin, displayFullScreenImage],
  props: {
    imgsrc: {
      type: String,
      required: true,
    },
    dataId: {
      type: String,
      required: false,
      default: '',
    },
    blurHash: {
      type: String,
      required: false,
      default: '',
    },
    width: {
      type: Number,
      required: false,
      default: 0,
    },
    height: {
      type: Number,
      required: false,
      default: 0,
    },
    id: {
      type: String,
      requiered: true,
      default: '',
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      imageLoaded: false,
      observer: null,
      divStyle: {
        position: 'relative',
        width: `${this.respWidth}px`,
        height: `${this.respHeight}px`,
      },
      options: {
        root: null,
        rootMargin: '0px',
        threshold: [0.2],
      },
      placeholderStyle: {
        opacity: '1',
        top: 0,
        right: 0,
        height: `${this.respHeight}px`,
        width: `${this.respWidth}px`,
        pointerEvents: 'none',
        borderRadius: '8px',
        overflow: 'hidden',
      },
      isInViewport: false,
      intersectTimeout: null,
      showOptionDropdown: false,
      file: null,
      isUnsplashsImage: false,
      dropdownTop: null,
      dropdownRight: null,
      scrollParent: null,
      selectedMenuItem: 0,
      blurWidth: this.width,
      blurHeight: this.height,
      respWidth: this.width,
      respHeight: this.height,
      throttleTimeout: null,
      showBlur: true,
      opacityTimeout: null,
      blurHideTimeout: null,
      imgStyle: {
        opacity: 0,
        borderRadius: '8px',
      },
    };
  },
  computed: {
    showBlurHash() {
      if (process.env.VUE_APP_ID === 'public') return false;
      return this.blurHash !== '' && this.isInViewport && this.showBlur;
    },
    imageSrc() {
      return this.imgsrc;
    },
    dropdownWidth() {
      return dropdownWidth;
    },
    optionsWrapStype() {
      return optionsWrapStype;
    },
    optionButtonStyle() {
      return optionButtonStyle;
    },
    iconStyle() {
      return iconStyle;
    },
    imageOptionItems() {
      return [
        {
          id: 'fullscreen',
          label: this.translate('fullscreen'),
          customIconName: this.iconPath('maximize-2'),
          meta: 'Space',
          shortcutKeyCode: 'Space',
        },
        {
          id: 'download',
          label: this.translate('download'),
          customIconName: this.iconPath('download'),
          separator: true,
        },
        {
          id: 'delete',
          label: this.translate('delete'),
          customIconName: this.iconPath('trash'),
          meta: 'Del',
          shortcutKeyCode: 'Delete',
        },
      ];
    },
    $store: {
      get() {
        if (!window.StVueApp) return {};
        return window.StVueApp.$store;
      },
      set() {},
    },
    imageId() {
      let fileId = '';
      if (this.isUnsplashsImage) {
        const unsplashId = this.imgsrc.substring(
          this.imgsrc.indexOf('.com/') + 5,
          this.imgsrc.lastIndexOf('?')
        );
        fileId = `unsplash-${unsplashId}`;
      } else {
        const imgSrcSplit = this.imgsrc.split('/');
        fileId = imgSrcSplit[imgSrcSplit.length - 1];
      }
      return fileId;
    },
  },
  watch: {
    imageSrc() {
      this.intersectObs();
    },
    width() {
      this.onResizeHandler();
    },
    height() {
      this.onResizeHandler();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.onResizeHandler(true);
      this.blurHeight = Math.round(this.respHeight);
      this.blurWidth = this.respWidth;
    });
    EventBus.$on('contentSizeChange', this.hideImageOptionsDropdown);
    EventBus.$on('beforePrint', this.beforePrint);
    if (this.imgsrc.includes(this.$constants.UNSPLASH_BASE_URL)) {
      this.isUnsplashsImage = true;
    }
    this.intersectObs();
    window.addEventListener('resize', this.onResizeHandler);
  },
  destroyed() {
    if (window.stImageDropdownOpen) {
      window.stImageDropdownOpen = false;
    }
    EventBus.$off('contentSizeChange', this.hideImageOptionsDropdown);
    if (this.scrollParent) {
      this.scrollParent.removeEventListener('scroll', this.onScrollHandler);
    }
    window.removeEventListener('resize', this.onResizeHandler);
    if (this.opacityTimeout) {
      clearTimeout(this.opacityTimeout);
    }
    if (this.blurHideTimeout) {
      clearTimeout(this.blurHideTimeout);
    }
  },
  methods: {
    onResizeHandler(skipSetImage = false) {
      if (this.$el.clientWidth - 1 <= this.width) {
        this.respWidth = this.$el.clientWidth;

        if (!skipSetImage) {
          const time = this.respHeight < this.height ? 500 : 150;
          this.throttleSetImage(time);
        }

        const roundToThree = (num) => Math.round((num + Number.EPSILON) * 1000) / 1000;
        // get current aspect ratio of image from higher dimension
        if (this.width >= this.height) {
          const aspectRatio = this.width / this.height;
          this.respHeight = roundToThree(this.respWidth / aspectRatio);
        } else {
          const aspectRatio = this.height / this.width;
          this.respHeight = roundToThree(this.respWidth * aspectRatio);
        }

        this.placeholderStyle.height = `${this.respHeight}px`;
      }
    },
    throttleSetImage(time = 150) {
      if (this.throttleTimeout !== null) {
        clearTimeout(this.throttleTimeout);
        this.throttleTimeout = null;
      }
      this.throttleTimeout = setTimeout(() => {
        this.setImage();
      }, time);
    },
    intersectHandler([entry], observer) {
      const { isIntersecting } = entry;
      if (isIntersecting) {
        this.setImage(observer);
      }
    },
    intersectObs(observer) {
      if (this.intersectTimeout !== null) {
        clearTimeout(this.intersectTimeout);
        this.intersectTimeout = null;
      }
      this.intersectTimeout = setTimeout(() => {
        /* create an instance and pass the callback and options */
        this.observer = new IntersectionObserver(this.intersectHandler, this.options);
        /* finally observe the target element */
        this.observer.observe(this.$refs.stImage);
      }, 10);
    },
    beforePrint() {
      this.setImage();
      EventBus.$off('beforePrint', this.beforePrint);
    },
    setImage(observer = this.observer) {
      this.getFile();
      observer.disconnect();
      this.isInViewport = true;
      const image = new Image();
      const src = generateSrcUrl(this.imgsrc, this.respWidth, this.respHeight);
      image.onload = () => {
        this.$refs.imgNode.onload = () => {
          this.divStyle = {
            position: 'relative',
          };
        };
        Object.assign(this.$refs.imgNode, {
          src,
        });
        this.opacityTimeout = setTimeout(() => {
          this.imgStyle.opacity = 1;
        }, 50);
        this.blurHideTimeout = setTimeout(() => {
          this.showBlur = false;
        }, 1000);
        this.$nextTick(() => {
          this.imageLoaded = true;
        });
      };
      image.src = src;
    },
    handleSelectedMenuItemChange(index) {
      this.selectedMenuItem = index;
    },
    addMenuEventListeners() {
      window.addEventListener('keydown', this.handleKeyDown);
      window.addEventListener('keyup', this.handleKeyUp);
    },
    removeMenuEventListeners() {
      window.removeEventListener('keydown', this.handleKeyDown);
      window.removeEventListener('keyup', this.handleKeyUp);
    },
    handleKeyUp(event) {
      if (event.key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();
        this.hideImageOptionsDropdown();
      }
    },
    handleKeyDown(event) {
      if (['ArrowDown', 'ArrowUp', 'Tab'].includes(event.key)) {
        event.preventDefault();
        event.stopPropagation();
        this.changeSelection(event.key);
      }
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        const item = this.imageOptionItems[this.selectedMenuItem];
        this.handleImageOptionsDropdownItemsClick(item, this.selectedMenuItem, event);
      }
      if (event.code === 'Space') {
        event.preventDefault();
        event.stopPropagation();
        this.handleImageOptionsDropdownItemsClick(this.imageOptionItems[0], 0, event);
      }
      if (event.key === 'Delete') {
        event.preventDefault();
        event.stopPropagation();
        this.handleImageOptionsDropdownItemsClick(this.imageOptionItems[2], 2, event);
      }
    },
    changeSelection(arrowKey) {
      if (arrowKey === 'ArrowDown' || arrowKey === 'Tab') {
        if (this.selectedMenuItem < this.imageOptionItems.length - 1) {
          this.selectedMenuItem += 1;
          return;
        }
        this.selectedMenuItem = 0;
      }
      if (arrowKey === 'ArrowUp') {
        if (this.selectedMenuItem > 0) {
          this.selectedMenuItem -= 1;
          return;
        }
        this.selectedMenuItem = this.imageOptionItems.length - 1;
      }
    },
    toggleOptionsDropdown() {
      if (!this.showOptionDropdown) {
        this.addMenuEventListeners();
        this.calculateDropdownPosition();
      }
      this.showOptionDropdown = !this.showOptionDropdown;
      window.stImageDropdownOpen = this.showOptionDropdown;
      if (!this.showOptionDropdown) {
        this.selectedMenuItem = 0;
        this.removeMenuEventListeners();
      }
    },
    hideImageOptionsDropdown() {
      this.showOptionDropdown = false;
      window.stImageDropdownOpen = this.showOptionDropdown;
      this.selectedMenuItem = 0;
      this.removeMenuEventListeners();
    },
    handleImageOptionsDropdownItemsClick(item, _index, event) {
      const imageUrl = this.isUnsplashsImage ? this.imgsrc : this.file.url;
      switch (item.id) {
        case 'fullscreen':
          const editorId = this.$el?.offsetParent.closest('.markdown-input-field')?.id;
          EventBus.$emit('showFullScreenImage', this.imageId, editorId);
          break;
        case 'download':
          downloadFile(imageUrl, this.file?.name || 'Unsplash image');
          break;
        case 'duplicate':
          this.handleDuplicateItemClick();
          break;
        case 'delete':
          this.handleDelete();
          break;
        default:
          break;
      }
      event.preventDefault();
      event.stopPropagation();
      this.hideImageOptionsDropdown();
    },
    getFile() {
      if (!Object.keys(this.$store).length) return;
      if (this.isUnsplashsImage || this.id) return;
      this.$store
        .dispatch('getFile', this.imageId)
        .then((file) => {
          this.file = file;
        })
        .catch(() => {
          this.file = null;
        });
    },
    handleMouseLeave(evt) {
      if (this.showOptionDropdown) {
        this.hideImageOptionsDropdown();
      }
    },
    handleDuplicateItemClick() {
      EventBus.$emit('duplicateStImage', this.$refs.stImage.getRootNode().host);
    },
    handleDelete() {
      EventBus.$emit('deleteStImage', this.$refs.stImage.getRootNode().host);
    },
    calculateDropdownPosition() {
      const bounds = this.$refs.imageOptionsButton.getBoundingClientRect();
      this.dropdownTop = bounds.y + optionButtonDim + dropdownMargin;
      this.dropdownRight = bounds.x - dropdownWidth + optionButtonDim;
      this.scrollParent = this.getScrollParent(this.$refs.stImage);
      if (this.scrollParent) {
        this.scrollParent.addEventListener('scroll', this.onScrollHandler);
      }
    },
    getScrollParent(node) {
      if (node == null) {
        return null;
      }
      if (node.scrollHeight > node.clientHeight) {
        return node;
      }
      return this.getScrollParent(node.parentNode || node.getRootNode().host);
    },
    onScrollHandler() {
      this.hideImageOptionsDropdown();
      this.scrollParent.removeEventListener('scroll', this.onScrollHandler);
    },
  },
};
</script>
