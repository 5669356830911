import electronHelpersMixin from '@/mixins/electronHelpersMixin';
import isSafari from './isSafari';

export default {
  mixins: [electronHelpersMixin, isSafari],
  data() {
    return {
      displayOverlay: false,
    };
  },
  computed: {
    getOpenInDesktopAppLS() {
      return this.$store.getters.getOpenInDesktopApp;
    },
    isSettings() {
      const pathName = window.location.pathname;
      return pathName.endsWith('/settings') || pathName.includes('/settings/');
    },
    teamSlug() {
      return this.$store.getters.getTeamSlug;
    },
    disableOpeningLinksInApp() {
      return (
        window.location.pathname.includes('/desktop-redirect') ||
        this.isElectronApp ||
        this.isMobile ||
        this.isSettings ||
        this.getOpenInDesktopAppLS === false
      );
    },
  },
  mounted() {
    if (this.disableOpeningLinksInApp) {
      return;
    }

    // safari does not allow connecting to electron app's local express server
    // => always display notification for choosing to open in app
    if (this.isSafariCheck) {
      this.openInAppHandler();
      return;
    }

    this.isElectronAppRunning().then((installed) => {
      if (installed) this.openInAppHandler();
    });
  },
  methods: {
    openInAppHandler() {
      if (this.getOpenInDesktopAppLS === null) {
        this.displayChooseToOpenInDesktopAppNotif();
      } else {
        this.displayOverlay = true;
        this.openCurrentRouteInDesktopApp();
      }
    },
    async isElectronAppRunning() {
      for (const url of this.$constants.connectToDesktopAppUrls) {
        try {
          const response = await fetch(url);
          if (response.ok) {
            const { installed } = await response.json();
            return installed;
          }
        } catch (error) {
          continue;
        }
      }

      return false;
    },

    openCurrentRouteInDesktopApp() {
      // dont display desktop redirect overlay component if 'open in app'
      //  is triggered by clicking on button in toast notification
      if (this.displayOverlay) this.$store.dispatch('setShowRedirectedToAppOverlay', true);
      const url = new URL(window.location.href);
      const { pathname, search, hash } = url;
      window.location.href = `superthread:/${pathname}${search}${hash}`;
    },
    setOpenInDesktopAppLS(value) {
      this.$store.dispatch('setOpenInDesktopApp', value);
    },
    displayChooseToOpenInDesktopAppNotif() {
      this.displayOverlay = false;
      const notificationLabels = [
        {
          text: this.translate('electronOpenInApp'),
          type: this.$constants.uiNotificationElements.button,
          callback: this.openCurrentRouteInDesktopApp,
          buttonStyle: 'attention',
        },
        {
          text: this.translate('rememberChoice'),
          type: this.$constants.uiNotificationElements.checkBox,
          action: 'setOpenInDesktopApp',
        },
      ];
      this.$store.dispatch('addUiNotification', {
        message: `${this.translate('electronOpenInDesktopApp')}?`,
        icon: 'monitor',
        subtitle: this.translate('electronAppIsInstalled'),
        labels: notificationLabels,
        duration: -1,
      });
    },
  },
};
