import { decodeJwt } from 'jose';

export const decodeToken = (token) => {
  try {
    const decodedToken = decodeJwt(token);
    return decodedToken;
  } catch (error) {
    return null;
  }
};

export const isExpired = (exp) => {
  const expirationDate = new Date(exp * 1000);
  const currentDate = new Date();
  return expirationDate < currentDate;
};

export const isTokenExpired = (token) => {
  const decodedToken = decodeToken(token);
  if (!decodedToken) return true;
  return isExpired(decodedToken.exp);
};
