export enum MyWorkTabId {
  Assigned = 'assigned',
  Created = 'created',
}

export enum EpicTabId {
  Details = 'details',
  Cards = 'cards',
}

export type Tab = {
  id: MyWorkTabId | EpicTabId;
  label: string;
  icon?: string;
  disabled?: boolean;
  selected?: boolean;
};
