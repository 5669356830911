import state from './attachmentPreviewState';
import getters from './attachmentPreviewGetters';
import mutations from './attachmentPreviewMutations';
import actions from './attachmentPreviewActions';

export default {
  state,
  getters,
  actions,
  mutations,
};
