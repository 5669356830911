/**
 * Generates a unique key for a sprint based on the project ID and sprint ID.
 *
 * @param {number | string} projectId - The ID of the project.
 * @param {number | string} sprintId - The ID of the sprint.
 * @returns {string} The generated sprint key: ${projectId}s${sprintId}.
 */
const getSprintKey = (projectId: number | string = '', sprintId: number | string = ''): string => {
  return `${projectId}s${sprintId}`;
};

/**
 * Generates a unique key for a sprint list based on the project ID, sprint ID, and list ID.
 *
 * @param {number | string} projectId - The ID of the project.
 * @param {number | string} sprintId - The ID of the sprint.
 * @param {number | string} listId - The ID of the list.
 * @returns {string} The generated sprint list key: ${projectId}s${sprintId}-${listId}.
 */
const getSprintListKey = (
  projectId: number | string = '',
  sprintId: number | string = '',
  listId: number | string = ''
): string => {
  return `${getSprintKey(projectId, sprintId)}-${listId}`;
};

export { getSprintKey, getSprintListKey };
