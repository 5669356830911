import { IconType } from '@/types/iconTypes';

export default {
  computed: {
    iconEnum() {
      return IconType.Icon;
    },
    emojiEnum() {
      return IconType.Emoji;
    },
    imageEnum() {
      return IconType.Image;
    },
  },
};
