import getDashedTitle from '@/utilities/getDashedTitle';
import constants from '@/utilities/constants';
import { BoardLayout } from '@superthread-com/api';

export default async function (store: any, to: any, _from: any, next: any) {
  const teamSlug = store.getters.getTeamSlug;

  if (to.params.cardsLayout === BoardLayout.Timeline) {
    await store.dispatch('getAllTeamFeatures');

    if (!store.getters.getTeamFeatureEnabled(constants.featureFlagIds.timelineLayout)) {
      const path = `/${teamSlug}/board-${to.params.boardId}${getDashedTitle(
        to.params.boardTitle
      )}/board`;
      next({ path });
    }
  }
  if (to.params.boardId) {
    store.dispatch('setCurrentBoardId', to.params.boardId);
  }
  store.dispatch('setCurrentTabName', 'boards');
  next();
}
