/**
 * Onboarding module getters
 * @packageDocumentation
 * @category Store
 */
import { GetterTree } from 'vuex';
import { OnboardingState as State } from '@/types';

const getters: GetterTree<State, any> = {
  onboardingCurrentForm: (state) => state.currentForm,
  onboardingUserID: (state) => state.userID,
  onboardingTeamName: (state) => state.teamName,
  onboardingSubDomain: (state) => state.subdomain,
  onboardingWorkspace: (state) => state.onboardingWorkspace,
  onboardingErrorMessage: (state) => state.errorMessage,
  onboardingWorkspaceErrorMessage: (state) => state.workspaceErrorMessage,

  invitationCode: (state) => state.invitationCode,
  failedInvites: (state) => state.failedInvites,

  /** Returns the team id of the currently logged in user. */
  getTeamID: (state): string => state.currentTeamId,
  currentTeamName: (state) => state.currentTeamName,
  getSignupAnswers: (state) => state.signupAnswers,
  getGetStartedExpanded: (state) => state.getStartedExpanded,
  getGetStartedShowAll: (state) => state.getStartedShowAll,

  getOnboardingActions: (state) => state.onboardingActions || [],
};

export default getters;
