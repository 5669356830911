import type { RawLocation } from 'vue-router/types/router';
import type { Store } from 'vuex';

export type RoleList = { allowed: string[] } | { disallowed: string[] };

/**
 * @param store
 * @param redirect The list of redirects. Provide undefined if you want to use this with next() within a beforeEnter guard.
 * @param roles
 * @returns
 */
export default function (
  store: Store<any>,
  redirect: { pass: RawLocation | undefined; fail: RawLocation | undefined },
  roles: RoleList
): RawLocation | undefined {
  if (!store.getters.getUser.teams) return redirect.pass;
  const currentRole = store.getters.getUser.teams?.find(
    (team: any) => team.id === store.getters.getTeamID
  ).role;
  if ('allowed' in roles && roles.allowed) {
    if (roles.allowed.includes(currentRole)) {
      return redirect.pass;
    }
  }
  if ('disallowed' in roles && roles.disallowed) {
    if (!roles.disallowed.includes(currentRole)) {
      return redirect.pass;
    }
  }
  return redirect.fail;
}
