const changeLanguage = (i18nextInstance, language) => {
  const [lang] = language.split('-');
  if (i18nextInstance.languages.includes(lang)) {
    i18nextInstance.changeLanguage(lang);
    return Promise.resolve();
  } else {
    const importLanguage = async () =>
      import(`@superthread-com/localization/languages/${lang}/index.js`);
    return importLanguage()
      .then(({ default: translations }) => {
        i18nextInstance.addResources(lang, 'translation', translations);
        i18nextInstance.changeLanguage(lang);
      })
      .catch((error) => {
        console.log('Error loading translations:', error);
        i18nextInstance.changeLanguage('en');
      });
  }
};

export { changeLanguage };
