export const getDefaultChildCardDraftObject = (isModal) => ({
  title: '',
  content: '',
  board_id: '',
  list_id: '',
  members: [],
  tags: [],
  priority: 0,
});

export const convertChildCardDraftToChildCard = (childCardDraft) => {
  const { title, content, boardId, board_id, board, listId, list_id, members, tags, priority } =
    childCardDraft || {};
  const boardIdValue = board?.id || boardId || board_id;
  const listIdValue = listId || list_id;

  const childCard = {
    ...getDefaultChildCardDraftObject(),
    ...(title && { title }),
    ...(content && { content }),
    ...(boardIdValue && { board_id: boardIdValue }),
    ...(listIdValue && { list_id: listIdValue }),
    ...(members && { members: members.map((member) => ({ user_id: member.user_id })) }),
    ...(tags && { tags: [{ ids: tags.map((tag) => tag.id) }] }),
    ...(priority && { priority }),
  };

  return childCard;
};
