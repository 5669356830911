/* eslint-disable no-console, no-undef */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      document.dispatchEvent(new CustomEvent('swReady'));
    },
    registered(reg) {
      setInterval(
        () => {
          reg.update();
        },
        1000 * 60 * 60
      ); // e.g. hourly checks
    },
    cached() {},
    updatefound() {},
    updated(registration) {
      document.dispatchEvent(new CustomEvent('swUpdated', { detail: registration }));
    },
    offline() {},
    error() {},
  });
}
