import { Tag, TagColor } from '@superthread-com/api';

export interface SuggestionTag extends Tag {
  name: string;
  color: TagColor;
}

export enum SuggestionType {
  tag = 'tag',
  relation = 'relation',
}

export type SuggestionTooltipType = 'accept' | 'reject' | 'default';

export interface RemoveSuggestionPayload {
  type: SuggestionType;
  card_id?: string;
  tagName?: string;
  ignoreSuggestion?: boolean; // ignore suggestion so it wont appear again for the current card
}
