import getDashedTitle from '@/utilities/getDashedTitle';
import { translate } from '@/utilities';
import cons from '@/utilities/constants';
import { ResourceType } from '@/types/resources';
import { getSprintKey } from '@/utilities/sprintKeys';

function makeCardRouteHandler(
  store: any,
  router: any,
  _to: any,
  _from: any,
  type: ResourceType.Card | ResourceType.Epic
) {
  const previousRoute = sessionStorage.getItem('route');
  const teamSlug = store.getters.getTeamSlug;
  const previousPathByType = type === ResourceType.Epic ? cons.epicRoute.path : 'card';
  const reloadWithPreviousRoute =
    _from.fullPath === '/' && // Coming from the root path
    previousRoute &&
    previousRoute !== '/' &&
    previousRoute !== '/login' && // Previous route is not root or login
    !previousRoute.includes(`${teamSlug}/${previousPathByType}-`); // Previous route doesn't include '/card-' or '/project-'
  const reloadWithoutPreviousRoute =
    _from.fullPath === '/' &&
    (!previousRoute || previousRoute === '/' || previousRoute === '/login');
  const cardOpenedFromSettings = previousRoute?.includes('/settings/');
  let toPath = _to.fullPath.replace(
    /\?userNavigated=true|&userNavigated=true|&cardIdButton=true|\?cardIdButton=true|\?cardMoved=true|&cardMoved=true/g,
    ''
  );

  if (type === ResourceType.Epic && _to.params?.selectedTabId) {
    const tabIdToAdd = `/${_to.params.selectedTabId}`;
    if (!toPath.endsWith(tabIdToAdd)) {
      toPath += tabIdToAdd;
    }
  }

  const isCardMoved = _to.query?.cardMoved;
  const usePush =
    isCardMoved || (['inbox'].includes(_from.name) && !store.getters.getIsAnyCardDisplayed);
  const isCardIdButtonClicked = _to.query?.cardIdButton;

  return function cardRouteHandler(data: any, type: ResourceType.Card | ResourceType.Epic) {
    const card = data;
    if (
      isCardMoved ||
      reloadWithoutPreviousRoute ||
      isCardIdButtonClicked ||
      cardOpenedFromSettings ||
      (reloadWithoutPreviousRoute && store.getters.getIsSettingsOpen)
    ) {
      let boardId = '';
      if (card.sprint_id) {
        boardId = getSprintKey(card.project_id, card.sprint_id);
      } else if (card.type === ResourceType.Epic) {
        boardId = cons.epicsBoardId;
      } else {
        boardId = card.board_id;
      }

      const projectId = card.project_id;
      const boardTitle = store.getters.getFlatBoards[boardId]?.title || '';
      const routeItemName = card.sprint_id ? 'sprint' : 'board';
      const dashedBoardTitle = getDashedTitle(boardTitle);

      let nextRoute = '';
      if (!boardId) {
        nextRoute = `/${teamSlug}/space-${card.project_id}/boards`;
      } else if (boardId === cons.epicsBoardId) {
        nextRoute = `/${teamSlug}/${cons.epicsBoardRoute.path}`;
      } else {
        nextRoute = `/${teamSlug}/${routeItemName}-${boardId}-${dashedBoardTitle}/${store.getters.cardsLayout()}`;
      }

      const boardRouteWithoutCardsLayout = `/${teamSlug}/${routeItemName}-${boardId}-${dashedBoardTitle}`;

      if (
        router.currentRoute.fullPath !== nextRoute &&
        router.currentRoute.fullPath !== boardRouteWithoutCardsLayout
      ) {
        // if usePush then push route instead of replace route to allow back navigation to inbox
        const routerCommand = usePush ? 'push' : 'replace';

        router[routerCommand]({
          path: card.sprint_id ? boardRouteWithoutCardsLayout : nextRoute,
        }).then(() => {
          // After successful navigation to the previous route, update history
          history.replaceState({ cardId: _to.params.cardId }, '', toPath);
          localStorage.setItem('lastVisitedRoute', toPath);

          if (isCardIdButtonClicked && boardId) {
            const notificationMessage =
              type === ResourceType.Epic
                ? translate('roadmapOpenedInTheBackground')
                : translate('boardOpenedInTheBackground', {
                    boardTitle,
                  });
            store.dispatch('setCurrentBoardId', boardId);
            store.dispatch('setCurrentProjectId', projectId);
            store.dispatch('addUiNotification', { message: notificationMessage });
          } else {
            // Set and display the card
            store.dispatch('setDisplayedCards', [_to.params.cardId]);
          }
        });
      }
    } else if (reloadWithPreviousRoute) {
      router.replace({ path: previousRoute }).then(() => {
        history.replaceState({ cardId: _to.params.cardId }, '', toPath);
        localStorage.setItem('lastVisitedRoute', toPath);

        // Fetch and display the card
        store.dispatch('setDisplayedCards', [_to.params.cardId]);
      });
    }

    if (
      _to.query.userNavigated &&
      !reloadWithPreviousRoute &&
      !isCardIdButtonClicked &&
      !reloadWithoutPreviousRoute
    ) {
      history.pushState({ cardId: _to.params.cardId }, '', toPath);
      localStorage.setItem('lastVisitedRoute', toPath);
    }
  };
}

function cardBeforeEnterHandler(store: any, router: any, to: any, from: any, next: any) {
  const type = to.name === cons.epicRoute.name ? ResourceType.Epic : ResourceType.Card;

  // add function to handle card
  const cardRouteHandler = makeCardRouteHandler(store, router, to, from, type);
  // check if card is already on state don't fetch it again
  if (store.getters.getCards[to.params.cardId]) {
    cardRouteHandler(store.getters.getCards[to.params.cardId], type);
  } else {
    store
      .dispatch('fetchCard', {
        cardId: to.params.cardId,
        type,
      })
      .then((card: any) => {
        cardRouteHandler(card, type);
      })
      .catch(() => {
        if (from.path === '/') {
          next({
            name: 'cardnotfound',
            params: {
              teamSlug: store.getters.getTeamSlug,
              cardId: to.params.cardId,
              cardTitle: to.params.cardTitle,
            },
          });
        } else {
          store.dispatch('itemNotFound', true);
        }
      });
  }
}

export { makeCardRouteHandler, cardBeforeEnterHandler };
