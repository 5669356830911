const getItemAndIndex = (itemsArray: any[], itemId: number, compareKey: string = 'id') => {
  let itemIndex: number = -1;
  const item = itemsArray.find((i: any, index: number) => {
    if (i[compareKey] && i[compareKey] === itemId) {
      itemIndex = index;
      return i;
    }
    return false;
  });

  return { item, itemIndex };
};

export default getItemAndIndex;
