import onPageLoad from '@/utilities/composables/useOnPageLoad';

export default {
  methods: {
    onPageLoad() {
      onPageLoad();
    },
    addLoader(loadingLabel = 'Loading') {
      const loader = document.createElement('div');
      loader.id = 'loading-wrapper';
      loader.innerHTML = `
        <div id="loading-wrapper">
          <div id="bird"></div>
          <div id="load-progress">
            ${loadingLabel}
          </div>
        </div>
      `;
      document.body.appendChild(loader);
    },
  },
};
