import { CardHint } from '@superthread-com/api';
import { RemoveSuggestionPayload, SuggestionType } from '@/types/suggestions';

export default (payload: RemoveSuggestionPayload, suggestions: CardHint[]) => {
  const { type, card_id, tagName } = payload;
  let index = -1;
  switch (type) {
    case SuggestionType.relation:
      index = suggestions.findIndex(
        ({ relation }: CardHint) => relation?.card?.card_id === card_id
      );
      break;
    case SuggestionType.tag:
      index = suggestions.findIndex(
        ({ tag }: CardHint) => tagName?.toLowerCase() === tag?.name?.toLowerCase()
      );
      break;
  }

  if (index > -1) {
    suggestions.splice(index, 1);
  }

  return suggestions;
};
