import getDefaultBoardState from './getDefaultBoardState';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export default {
  state: getDefaultBoardState(),
  getters,
  actions,
  mutations,
};
