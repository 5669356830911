import { Project } from '@superthread-com/api';

// Function to compare the time_created property of two projects
function compareTimeCreated(a: Project, b: Project) {
  return (a.time_created ?? 0) - (b.time_created ?? 0);
}

function compareProjectOrder(a: Project, b: Project, projectOrder: string[]): number {
  const aIndex = projectOrder.indexOf(a.id ?? '');
  const bIndex = projectOrder.indexOf(b.id ?? '');
  if (aIndex === -1 && bIndex === -1) {
    // sort by time created if neither project is in the project order
    return compareTimeCreated(a, b);
  }
  if (aIndex === -1) {
    return 1;
  }
  if (bIndex === -1) {
    return -1;
  }
  return aIndex - bIndex;
}

function sortProjects(projects: Project[] = [], projectOrder: string[] = []): Project[] {
  const sortedProjects = [...projects];
  return projectOrder.length
    ? sortedProjects.sort((a, b) => compareProjectOrder(a, b, projectOrder))
    : sortedProjects.sort(compareTimeCreated);
}

export default sortProjects;
