import EventBus from '../utilities/eventBus';

export default {
  methods: {
    hideFiltersDropdown() {
      EventBus.$emit('manualHidePopup');
      if (this.$store.getters.showFilterMenu) {
        this.$store.dispatch('showFilterMenu', false);
      }
      if (this.$store.getters.showSubHeaderFilterMenu) {
        this.$store.dispatch('showSubHeaderFilterMenu', false);
      }
    },
  },
};
