import { getCurrentInstance } from 'vue';
import useOnboardingActions from '@/utilities/composables/useOnboardingActions';
import getBranchName from '@/utilities/getBranchName';

const isInput = () => {
  const { tagName, isContentEditable } = document.activeElement as HTMLElement;
  return (
    tagName.toLowerCase() === 'input' || tagName.toLowerCase() === 'textarea' || isContentEditable
  );
};

const closeOtherPopups = (modalToOpen: string, getters: any, dispatch: any) => {
  if (modalToOpen === 'quickPage' && getters.getQuickCardIsOn) dispatch('setQuickCard', false);
  if (modalToOpen === 'quickCard' && getters.getQuickPage) dispatch('setQuickPage', false);
  if (getters.getDeletePopupId) dispatch('deletePopupId', '');
  if (getters.showSearchModal) dispatch('toggleSearchModal', false);
  if (getters.getEmojiPickerDisplayed) dispatch('setEmojiPickerDisplayed', '');
  if (getters.getIconPickerDropdown) dispatch('setIconPickerDropdown', '');
};

const quickCardShortcutHandlerFn = (
  event: KeyboardEvent,
  completeOnboardingActions: Function,
  getters: any,
  dispatch: any
) => {
  if (!isInput() && (event.code === 'KeyC' || event.key === 'C' || event.key === 'c')) {
    closeOtherPopups('quickCard', getters, dispatch);
    dispatch('setQuickCard', true);
    return true;
  }
  return false;
};

const quickPageShortcutHandlerFn = (
  event: KeyboardEvent,
  completeOnboardingActions: Function,
  getters: any,
  dispatch: any
) => {
  if (!isInput() && (event.code === 'KeyP' || event.key === 'P' || event.key === 'p')) {
    closeOtherPopups('quickPage', getters, dispatch);
    dispatch('setQuickPage', true);
    return true;
  }
  return false;
};

export default function useShortcutHandlers() {
  const _this: any = getCurrentInstance()?.proxy;
  if (!_this) return;

  const { dispatch, getters } = _this.$store;
  const { completeOnboardingActions } = useOnboardingActions() as any;

  const quickCardShortcutHandler = (event: KeyboardEvent) => {
    return quickCardShortcutHandlerFn(event, completeOnboardingActions, getters, dispatch);
  };

  const quickPageShortcutHandler = (event: KeyboardEvent) => {
    return quickPageShortcutHandlerFn(event, completeOnboardingActions, getters, dispatch);
  };

  const copyGitBranchNameShortcutHandler = (cardId: string | number) => {
    const card = _this.$store.getters.getCardById(cardId);
    const branchName = getBranchName(cardId, card.title);
    navigator.clipboard?.writeText?.(branchName);

    _this.$store.dispatch('addUiNotification', {
      message: _this.translate('branchCopiedToClipboard'),
      icon: 'git-pull-request',
      duration: 4000,
    });
  };

  return {
    copyGitBranchNameShortcutHandler,
    quickCardShortcutHandler,
    quickPageShortcutHandler,
    isInput,
  };
}
