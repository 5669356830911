import constants from '@/utilities/constants';

const isBoardFullyLoaded = (board) => {
  // *KNOWN BUG: boards with lists, where all lists do not contain any cards will be always fetched
  // TODO: to be fixed with this card: https://app.superthread.com/superthread/card-10112-isboardfullyloaded-check-not-working-properly
  return (
    Object.keys(board).length > constants.fullyLoadedBoardPropThreshold &&
    board.lists?.some((list) => !!list?.[board.id === constants.epicsBoardId ? 'epics' : 'cards'])
  );
};

export default isBoardFullyLoaded;
