import tippy from 'tippy.js';
import Vue from 'vue';
import EventBus from '@/utilities/eventBus';
import MemberHoverPreview from '@/components/widgets/MemberHoverPreview';

export default {
  data() {
    return {
      instance: null,
      showDelayValue: this.$constants.showMemberHoverPreviewDelay,
      showInstanceTimeout: null,
    };
  },
  beforeDestroy() {
    this.destroyInstance();
  },
  computed: {
    // currently displayed member hover preview - returns {memberId}/{groupAvatarId}
    displayedMemberHoverPreview() {
      return this.$store.getters.getMemberHoverPreview;
    },
    // previously displayed instances
    memberHoverPreviewInstances() {
      return this.$store.getters.getMemberHoverPreviewInstances;
    },
  },
  methods: {
    // main method for displaying member hover previews
    // gets called on mouseenter event on reference element
    displayMemberHoverPreview($event, member, groupAvatarId) {
      // group avatar id indicates that member belongs to a group of avatars (e.g. CurrentModelMembers)
      this.groupAvatarId = groupAvatarId || '';
      this.memberId = member.id || member.user_id || member.invitee_user_id || '';
      const referenceElement = $event.target;

      this.calculateShowDelayValue();
      this.tryGetExistingInstance(referenceElement);

      // if instance was previously displayed - updates show delay property and displays it manually
      if (this.instance) {
        this.updateShowDelayProp();
        this.manuallyShowInstance();
        return;
      }

      this.initializeInstance(referenceElement, member);
    },

    // if hover preview for the referenceElement was already displayed it trys to set this.instance to the one previously displayed
    // (to avoid unecessary creating of a new instance)
    tryGetExistingInstance(referenceElement) {
      this.instance =
        this.memberHoverPreviewInstances.find(({ reference, props }) => {
          return (
            reference === referenceElement &&
            props.memberId === this.memberId &&
            (props.groupAvatarId || '') === this.groupAvatarId
          );
        }) || null;
    },

    // calculates initial delay for displaying hover preview
    // enables transition with 0 between hover previews for the same group of avatars
    // (only if hover preview is already displayed for the member of the same group)
    calculateShowDelayValue() {
      const displayedInstanceGroupAvatarId = this.displayedMemberHoverPreview.split('/')?.[1] || '';

      // delay is set to 0 only if hover preview is already displayed for the same member in group
      this.showDelayValue =
        this.groupAvatarId && this.groupAvatarId === displayedInstanceGroupAvatarId
          ? 0
          : this.$constants.showMemberHoverPreviewDelay;
    },

    // if instance already exists - updates tippy instance delay property
    // (tippy props are not reactive, so we need to update them manually)
    updateShowDelayProp() {
      if (this.showDelayValue !== this.instance.props.delay[0]) {
        this.instance.setProps({
          delay: [this.showDelayValue, 0],
        });
      }
    },
    manuallyShowInstance() {
      if (!this.showDelayValue) {
        this.instance.show();
        return;
      }

      this.showInstanceTimeout = setTimeout(() => {
        this.instance.show();
      }, this.showDelayValue);
    },

    // creates new tippy instance
    initializeInstance(reference, member) {
      if (!reference || !this.memberId || !Object.keys(member).length) return;

      this.instance = tippy(reference, {
        content: () => {
          return this.createContentForInstance(member);
        },
        appendTo: () => document.body,
        zIndex: 10000,
        trigger: 'mouseenter',
        placement: 'top-start',
        interactive: true,
        delay: [this.showDelayValue, 0],
        duration: 0,
        arrow: false,
        offset: [0, 8],
        showOnCreate: true,
        onUntrigger: () => {
          this.clearShowInstanceTimeout();
        },
        onShow: () => {
          EventBus.$on('viewWorkClick', this.destroyInstance);
          window.addEventListener('keypress', this.destroyInstance);
          this.setMemberHoverPreviewOn();
        },
        onShown: () => {
          this.hideRestOfInstances();
        },
        onHide: () => {
          EventBus.$off('viewWorkClick', this.destroyInstance);
          window.removeEventListener('keypress', this.destroyInstance);
          this.setMemberHoverPreviewOff();
        },
      });

      // add props to instance in order to retrieve it from store when getting when triggered again
      const { groupAvatarId, memberId } = this;
      this.instance.setProps({
        ...(groupAvatarId && { groupAvatarId }),
        ...(memberId && { memberId }),
      });
      this.addInstanceToStore();
    },
    createContentForInstance(member) {
      return new Vue({
        store: this.$store,
        router: this.$router,
        render: (h) =>
          h(MemberHoverPreview, {
            class: { animation: this.showDelayValue },
            props: {
              member,
            },
          }),
      }).$mount().$el;
    },

    destroyInstance() {
      if (this.instance) {
        this.clearShowInstanceTimeout();
        this.instance?.destroy();
        this.removeInstanceFromStore();
        this.instance = null;
        this.setMemberHoverPreviewOff();
      }
    },
    clearShowInstanceTimeout() {
      clearTimeout(this.showInstanceTimeout);
      this.showInstanceTimeout = null;
    },
    addInstanceToStore() {
      if (this.instance) this.$store.dispatch('addMemberHoverPreviewInstance', this.instance);
    },
    removeInstanceFromStore() {
      if (this.instance) this.$store.dispatch('removeMemberHoverPreviewInstance', this.instance);
    },
    hideRestOfInstances() {
      this.memberHoverPreviewInstances.forEach((instance) => {
        if (instance.id !== this.instance.id) instance.hide();
      });
    },

    // controls currently displayed member hover preview with memberId / groupAvatarId if it belongs to a group
    setMemberHoverPreviewOn() {
      this.$store.dispatch('setMemberHoverPreview', `${this.memberId}/${this.groupAvatarId}`);
    },
    setMemberHoverPreviewOff() {
      if (`${this.memberId}/${this.groupAvatarId}` === this.displayedMemberHoverPreview)
        this.$store.dispatch('setMemberHoverPreview', '');
    },
  },
};
