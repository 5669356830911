import cardStatusType from '@/types/cardStatusType';

type StatusKey = (typeof cardStatusType)[keyof typeof cardStatusType];

const getCardStatus = (status: StatusKey, isEpic: boolean = false) => {
  return getDefaultCardStatuses(isEpic)[status];
};

const getDefaultCardStatuses = (isEpic: boolean = false) => {
  return {
    backlog: {
      label: 'Backlog',
      icon: isEpic ? 'hex-16' : 'circle-dotted',
      id: cardStatusType.Backlog,
      color: '#98A2B3',
    },
    committed: {
      label: 'Not started',
      icon: isEpic ? 'hexagon-16' : 'disc',
      id: cardStatusType.Committed,
      color: '#98A2B3',
    },
    started: {
      label: 'In progress',
      icon: isEpic ? 'play-hex-16' : 'play-circle',
      id: cardStatusType.Started,
      color: '#498CF7',
    },
    completed: {
      label: 'Done',
      icon: isEpic ? 'check-hex-16' : 'check-circle',
      id: cardStatusType.Completed,
      color: '#32D583',
    },
    cancelled: {
      label: 'Cancelled',
      icon: isEpic ? 'x-hex-16' : 'x-circle',
      id: cardStatusType.Cancelled,
      color: '#F79009',
    },
    rolledover: {
      label: 'Rolled over',
      icon: 'arrow-right-circle',
      id: cardStatusType.Rolledover,
      color: '#475467',
    },
  };
};

const getBoardLists = (isEpic: boolean = false) => {
  return [
    {
      id: getCardStatus(cardStatusType.Backlog, isEpic).id,
      label: getCardStatus(cardStatusType.Backlog, isEpic).label,
      title: 'Backlog',
      behavior: getCardStatus(cardStatusType.Backlog, isEpic).id,
      icon: getCardStatus(cardStatusType.Backlog, isEpic).icon,
      color: getCardStatus(cardStatusType.Backlog, isEpic).color,
    },
    {
      id: getCardStatus(cardStatusType.Committed, isEpic).id,
      label: getCardStatus(cardStatusType.Committed, isEpic).label,
      title: 'To do',
      behavior: getCardStatus(cardStatusType.Committed, isEpic).id,
      icon: getCardStatus(cardStatusType.Committed, isEpic).icon,
      color: getCardStatus(cardStatusType.Committed, isEpic).color,
    },
    {
      id: getCardStatus(cardStatusType.Started, isEpic).id,
      label: getCardStatus(cardStatusType.Started, isEpic).label,
      title: 'Doing',
      behavior: getCardStatus(cardStatusType.Started, isEpic).id,
      icon: getCardStatus(cardStatusType.Started, isEpic).icon,
      color: getCardStatus(cardStatusType.Started, isEpic).color,
    },
    {
      id: getCardStatus(cardStatusType.Completed, isEpic).id,
      label: getCardStatus(cardStatusType.Completed, isEpic).label,
      title: 'Done',
      behavior: getCardStatus(cardStatusType.Completed, isEpic).id,
      icon: getCardStatus(cardStatusType.Completed, isEpic).icon,
      color: getCardStatus(cardStatusType.Completed, isEpic).color,
    },
    {
      id: getCardStatus(cardStatusType.Cancelled, isEpic).id,
      label: getCardStatus(cardStatusType.Cancelled, isEpic).label,
      title: 'Cancelled',
      behavior: getCardStatus(cardStatusType.Cancelled, isEpic).id,
      icon: getCardStatus(cardStatusType.Cancelled, isEpic).icon,
      color: getCardStatus(cardStatusType.Cancelled, isEpic).color,
    },
  ];
};

const defaultSprintStatuses = [
  {
    title: getCardStatus(cardStatusType.Committed).label,
    status: getCardStatus(cardStatusType.Committed).id,
  },
  {
    title: getCardStatus(cardStatusType.Started).label,
    status: getCardStatus(cardStatusType.Started).id,
  },
  {
    title: getCardStatus(cardStatusType.Completed).label,
    status: getCardStatus(cardStatusType.Completed).id,
  },
  {
    title: getCardStatus(cardStatusType.Cancelled).label,
    status: getCardStatus(cardStatusType.Cancelled).id,
  },
  {
    title: getCardStatus(cardStatusType.Rolledover).label,
    status: getCardStatus(cardStatusType.Rolledover).id,
  },
];

export { getCardStatus, getBoardLists, defaultSprintStatuses, getDefaultCardStatuses };
