import { getBoardLists, getDefaultCardStatuses } from '@/utilities/defaultCardStatuses';

export default {
  computed: {
    cardStatuses() {
      return getDefaultCardStatuses();
    },
  },

  methods: {
    defaultLists(isEpic = false) {
      return getBoardLists(isEpic);
    },
  },
};
