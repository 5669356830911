export default {
  computed: {
    isViewOpen() {
      return [
        this.$constants.routeNames.view,
        this.$constants.routeNames.views,
        this.$constants.routeNames.newView,
      ].includes(this.$route.name);
    },
  },
};
