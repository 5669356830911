import { User } from '../../types';
import constants from '@/utilities/constants';

const userHashColor = (user: User | string) => {
  const id: string = typeof user === 'string' ? user : (user?.user_id ?? user?.id);

  if (!id) {
    return constants.userColors[0];
  }

  const colorIndex =
    id.split('').reduce((prev, curr) => prev + curr.charCodeAt(0), 0) % constants.userColors.length;

  return constants.userColors[colorIndex];
};

const useUserHashColor = () => userHashColor;

export { userHashColor, useUserHashColor };
