import { ResourceType } from '@/types/resources';
import { DisplayedCard, DisplayedCardPayload } from '@/types/displayedCard';
import constants from '@/utilities/constants';

// This utility function is used to normalize the payload
// used for display card, it allows passing payload in different formats
const getNormalizedDisplayedCardPayload = (
  rs: any, // rootState
  payload: DisplayedCardPayload = []
): DisplayedCard[] => {
  if (!payload.length) {
    return [];
  }

  if (typeof payload === 'string') {
    payload = [payload];
  }

  const getCardType = (cardId: string) => rs.boards.cards[cardId]?.type || ResourceType.Card;

  const getCardLayout = (cardId: string): string => {
    const isEpic = rs.boards.cards[cardId]?.type === ResourceType.Epic;
    return isEpic ? constants.fullScreen : rs.boards.currentCardLayout;
  };

  const normalizePayload = (cardId: string): DisplayedCard => {
    return {
      id: cardId,
      layout: getCardLayout(cardId),
      resource_type: getCardType(cardId),
      comment_activity_order: rs.commentsOrder || 'newest',
      comment_activity_filter: rs.cardCommentActivityFilter || 'all',
    };
  };

  const arrDisplayedCards: DisplayedCard[] = [];
  for (const c of payload) {
    if (typeof c === 'string') {
      arrDisplayedCards.push(normalizePayload(c));
    } else {
      // allow passing in custom values for displayed cards prop
      arrDisplayedCards.push({ ...normalizePayload(c.id), ...c });
    }
  }

  return arrDisplayedCards;
};

export { getNormalizedDisplayedCardPayload };
