import Vue from 'vue';
import Vuex from 'vuex';

import getDefaultState from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import modules from './modules';

// @ts-ignore
Vue.use(Vuex);

export default new Vuex.Store({
  strict: false,
  state: getDefaultState(),
  actions,
  mutations,
  getters,
  modules,
});
