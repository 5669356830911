const isInViewport = (
  elem,
  boundElem,
  boundingOffests = { top: 0, right: 0, bottom: 0, left: 0 }
) => {
  const rect = elem.getBoundingClientRect();
  const rectAgainst = boundElem.getBoundingClientRect
    ? boundElem.getBoundingClientRect()
    : boundElem.$el.getBoundingClientRect();
  const boundOffsets = { ...{ top: 0, right: 0, bottom: 0, left: 0 }, ...boundingOffests };
  const offsetedAgainstTop = rectAgainst.top + boundOffsets.top;
  const offsetedAgainsBottom = rectAgainst.bottom - boundOffsets.bottom;

  const vertical =
    (rect.bottom >= offsetedAgainstTop &&
      rect.top >= offsetedAgainstTop &&
      rect.bottom <= offsetedAgainsBottom) ||
    (rect.top <= offsetedAgainsBottom &&
      rect.bottom <= offsetedAgainsBottom &&
      rect.top >= offsetedAgainstTop);
  const horizontal = rect.right <= rectAgainst.right && rect.left >= rectAgainst.left;
  let deltaH = 0;
  if (!horizontal) {
    if (rect.right > rectAgainst.right) {
      deltaH = rect.right - rectAgainst.right;
    } else if (rect.left < rectAgainst.left) {
      deltaH = rectAgainst.left - rect.left;
    }
  }
  let deltaV = 0;
  if (!vertical) {
    if (rect.bottom > offsetedAgainsBottom) {
      deltaV = rect.bottom - offsetedAgainsBottom;
    } else if (rect.top < offsetedAgainstTop) {
      deltaV = -(offsetedAgainstTop - rect.top);
    }
  }

  return {
    vertical,
    horizontal,
    deltaH,
    deltaV,
  };
};

export default isInViewport;
