export default {
  ops: {
    rail: {
      opacity: 0,
      size: '6px',
      gutterOfSide: '8px',
    },
    bar: {
      background: 'rgba(69, 69, 69, 0.16)',
      opacity: 1,
      specifyBorderRadius: '4px',
      size: '6px',
    },
  },
};
