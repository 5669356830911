import { ObjectDirective, DirectiveBinding, VNode } from 'vue';

declare global {
  interface HTMLElement {
    vdraghover: {
      className: string;
      classAdded?: boolean;
      dragWatch?: () => void;
      mouseEnterHandler?: (event: MouseEvent) => void;
      mouseOverHandler?: (event: MouseEvent) => void;
      mouseLeaveHandler?: (event: MouseEvent) => void;
    };
  }
}

const addClass = (el: HTMLElement, className: string) => {
  el.classList.add(className);
  el.vdraghover.classAdded = true;
};

const removeClass = (el: HTMLElement, className: string) => {
  el.classList.remove(className);
  el.vdraghover.classAdded = false;
  el.vdraghover.dragWatch?.();
};

const onMouseOver = (el: HTMLElement, context: any) => (event: MouseEvent) => {
  if (el.vdraghover.classAdded) return;
  if (context?.draggingIsOn) {
    addClass(el, el.vdraghover.className);
  }
};

const onMouseEnter = (el: HTMLElement, context: any) => () => {
  if (context?.draggingIsOn) {
    // Code to execute when draggingIsOn is true
    addClass(el, el.vdraghover.className);

    el.vdraghover.dragWatch = context?.$watch('draggingIsOn', (newValue: boolean) => {
      if (!newValue) {
        removeClass(el, el.vdraghover.className);
      }
    });
  }
};

const onMouseLeave = (el: HTMLElement, context: any) => () => {
  if (!el.vdraghover.classAdded) return;
  if (context?.draggingIsOn) {
    // Code to execute when draggingIsOn is true
    removeClass(el, el.vdraghover.className);
  }
};

const extractValues = (value: any) => {
  let className = '';
  let childDrag = false;

  if (typeof value === 'object') {
    if ('className' in value) {
      className = value.className as string;
    }
    if ('childDrag' in value) {
      childDrag = value.childDrag as boolean;
    }
  } else {
    className = value as string;
  }

  return { className, childDrag };
};

const directive: ObjectDirective = {
  inserted: (el: HTMLElement, binding: DirectiveBinding<VNode>, vnode: VNode) => {
    el.vdraghover = {
      className: '',
    };
    const { value } = binding;
    const { context } = vnode;

    const { className, childDrag } = extractValues(value);

    el.vdraghover.className = className;

    el.vdraghover.mouseOverHandler = onMouseOver(el, context);
    el.vdraghover.mouseEnterHandler = onMouseEnter(el, context);
    el.vdraghover.mouseLeaveHandler = onMouseLeave(el, context);

    if (childDrag) {
      // mouseenter will not fire when child element is dragged in el parent
      el.addEventListener('mousemove', el.vdraghover?.mouseOverHandler);
    }

    el.addEventListener('mouseenter', el.vdraghover.mouseEnterHandler);
    el.addEventListener('mouseleave', el.vdraghover.mouseLeaveHandler);
  },
  update: (el: HTMLElement, binding: DirectiveBinding<any>, vnode: VNode) => {
    const { value, oldValue } = binding;
    const { childDrag } = extractValues(value);
    const { childDrag: oldChildDrag } = extractValues(oldValue);
    // CHECK IF draghover object has property mouseOverHandler
    if (!oldChildDrag && childDrag && el.vdraghover.mouseOverHandler) {
      el.removeEventListener('mousemove', el.vdraghover.mouseOverHandler);
      el.addEventListener('mousemove', el.vdraghover.mouseOverHandler);
    }
  },

  unbind: (el) => {
    if (el.vdraghover.mouseOverHandler) {
      el.removeEventListener('mousemove', el.vdraghover.mouseOverHandler);
    }
    if (el.vdraghover.mouseEnterHandler) {
      el.removeEventListener('mouseenter', el.vdraghover.mouseEnterHandler);
    }
    if (el.vdraghover.mouseLeaveHandler) {
      el.removeEventListener('mouseleave', el.vdraghover.mouseLeaveHandler);
    }
    if (el.vdraghover.dragWatch) {
      el.vdraghover.dragWatch();
    }
  },
};

export default directive;
